import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocaleLabelPipe } from "./pipes/locale-label.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { MetaTranslateComponent } from './components/meta-translate/meta-translate.component';
import { LocaleDescLabelPipe } from './pipes/locale-desc-label.pipe';

@NgModule({
    declarations: [LocaleLabelPipe, MetaTranslateComponent, LocaleDescLabelPipe],
    imports: [CommonModule, TranslateModule],
    exports: [LocaleLabelPipe, TranslateModule,MetaTranslateComponent,LocaleDescLabelPipe],
})
export class MetaSharedModule {}
