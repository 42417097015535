import { of, Observable } from "rxjs";
export class MetaFormsUtils {
    static defaultConfig = {
        dataTypeId: 201,
        fieldTypeId: 101,
        isMandatory: true,
        isRepeatable: true,
        isRiskRatingMandatory: null,
        maxLength: 5,
        minLength: 2,
        isReadOnly: null,
    };
    static convertToMetaForm(fields: any[], country: any): any[] {
        const result: any[] = [];
        fields.map((field, i) => {
            const {
                id,
                localeData = [],
                displayOrder,
                parentContentId,
                isDisabled,
                mnemonicCode,
                isUserRoleName,
                isCheckerReviewer,
                profileStatus,
                additionalConfig: [config],
                compositeAdditionalConfig = [],
                conditionalAdditionalConfig = [],
                options,
                bookingLocationId,
                composite,
                conditional = field.items[0].conditional ? true : false,
            } = field;
            const items = [];
            const {
                dataTypeId,
                headerTypeId,
                sourceValueId,
                isNameScreening,
                fieldTypeId,
                isMandatory,
                minLength,
                maxLength,
                isRepeatable,
                isReadOnly,
                isRiskRatingMandatory,
            } = config || this.defaultConfig;
            result.push({
                id,
                parentContentId,
                localeData,
                items,
                displayOrder,
                isDisabled,
                isMandatory,
                isRepeatable,
                isUserRoleName,
                profileStatus,
                isCheckerReviewer,
                fieldTypeId,
                headerTypeId,
                dataTypeId,
                minLength,
                maxLength,
                options,
                isRiskRatingMandatory,
                bookingLocationId,
                isReadOnly,
                mnemonicCode,
                isNameScreening,
                composite,
                conditional,
            });
            field.items.map((item) => {
                const {
                    id,
                    fieldId,
                    value,
                    compositeFieldList,
                    conditionalFieldList,
                    fieldReviewInfo,
                    bookingLocationId,
                    composite,
                    conditional,
                } = item;
                const compositeFieldListItems = compositeFieldList || [];
                const conditionalFieldListItems = conditionalFieldList || [];
                // console.log(conditionalFieldListItems);
                // let  = Array.isArray(value) ? value.toString() : value;
                const dupValue = value
                    ? value
                    : fieldTypeId === 103
                    ? sourceValueId
                    : fieldTypeId === 104
                    ? sourceValueId.toString().split(",")
                    : sourceValueId;
                result[i].items.push({
                    id,
                    fieldId,
                    composite,
                    conditional,
                    fieldReviewInfo,
                    value:
                        fieldTypeId === 104
                            ? dupValue
                                ? dupValue.toString().split(",")
                                : dupValue
                            : dupValue,
                    compositeFieldListItems: compositeFieldListItems.map(
                        (compositeItem) => {
                            const { id, fieldId, value } = compositeItem;
                            const compositeDetails = compositeAdditionalConfig
                                .filter((abc) => abc.id === fieldId)
                                .pop();
                            let cloneCompositeItem = JSON.parse(
                                JSON.stringify(compositeItem)
                            );
                            cloneCompositeItem.value =
                                compositeDetails?.additionalConfig[0]
                                    .fieldTypeId === 104
                                    ? dupValue
                                        ? dupValue.toString().split(",")
                                        : dupValue
                                    : dupValue;
                            return Object.assign(
                                {},
                                cloneCompositeItem,

                                {
                                    compositeDetails,
                                }
                            );
                        }
                    ),
                    conditionalFieldListItems: conditionalFieldListItems.map(
                        (conditionalItem) => {
                            const { childFieldId, value } = conditionalItem;
                            const conditionalDetails =
                                conditionalAdditionalConfig
                                    .filter((abc) => abc.id == childFieldId)
                                    .pop();
                            let dupConditionalItem = JSON.parse(
                                JSON.stringify(conditionalItem)
                            );
                            dupConditionalItem.value =
                                !Array.isArray(value) &&
                                conditionalDetails?.additionalConfig[0]
                                    .fieldTypeId === 104
                                    ? dupValue
                                        ? dupValue.toString().split(",")
                                        : dupValue
                                    : dupValue;
                            // const valueArray = value?.split(",");
                            // conditionalItem.value = valueArray;
                            return Object.assign({}, dupConditionalItem, {
                                conditionalDetails,
                            });
                        }
                    ),

                    bookingLocationId:
                        country.filter(
                            (country) => country.id === bookingLocationId
                        )[0] || [],
                    addFlag: false,
                });
            });
        });
        // console.log(result);

        return result.sort((a, b) => {
            if (a.parentContentId === b.parentContentId) {
                return a.displayOrder - b.displayOrder;
            } else {
                return a.parentContentId - b.parentContentId;
            }
        });
    }

    static convertToPostData(response: any[]): any[] {
        const data = [];
        for (const [fieldId, value] of Object.entries(response)) {
            value.reduce((accumulator, field) => {
                const [id, ...skip] = Object.keys(field);
                const value = Array.isArray(field[id])
                    ? field[id].join(",")
                    : field[id];
                accumulator.push(
                    Object.assign(
                        {
                            fieldId,
                            value,
                        },
                        id !== "null" ? { id } : {}
                    )
                );
                return accumulator;
            }, data);
        }
        return data;
    }
}
