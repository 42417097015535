import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionTimeoutDialogComponent } from './components/session-timeout-dialog/session-timeout-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [SessionTimeoutDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule
  ],
  entryComponents: [
         SessionTimeoutDialogComponent
      ],
  exports: [SessionTimeoutDialogComponent],
})
export class SessionTimeoutModule { }
