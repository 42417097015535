import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilService } from 'projects/shared/src/public_api';

@Component({
  selector: 'app-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.scss']
})
export class SessionTimeoutDialogComponent implements OnInit {


  constructor(
    private dialogRef: MatDialogRef<SessionTimeoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private utilService: UtilService,
  ) { }

  ngOnInit(): void {
    this.utilService.loadTanslations("session-timeout");
    console.log(this.data)
    setInterval(()=>{
      this.data.seconds-=1;
      if(this.data?.seconds==1){
        this.dialogRef.close("LEAVE");
      }
    },1000)
  }

  leave(){
    this.dialogRef.close("LEAVE");
  }
  stay(){
    this.dialogRef.close("STAY");
  }

}
