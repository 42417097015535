import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT, Location } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject, forkJoin, Subscription, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { navigation } from "app/navigation/navigation";
import { locale as navigationEnglish } from "app/navigation/i18n/en";
import { locale as navigationTurkish } from "app/navigation/i18n/tr";
import { HttpClient } from "@angular/common/http";

import {
    defaults,
    fuseConfigOnboard,
    fuseConfigActive,
} from "./diligent-config/config";

import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import {
    SharedService,
    StorageService,
    TermsConditionsComponent,
} from "../../projects/shared/src/public_api";
import { SSOService } from "./oauth2/sso/sso.service";
import { MainService } from "./services/main.service";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import * as fromAppReducers from "./store/app.reducers";
import { environment } from "environments/environment";
import { ArrayUtils } from "projects/onboarding/src/lib/corporate/utils/array.utils";

@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    forkSubscribe: Subscription;
    fuseConfig: any;
    navigation: any;
    isConfigurd: any;
    role: string = this.storageService.role;
    defaultLang: string = "en";
    defaultLangs: string[] = this.storageService.languages || ["en"];
    // Private
    private _unsubscribeAll: Subject<any>;
    roleDetails: any;
    roleData: { [key: string]: string } = {
        maker: "USR_RLS_MAK",
        checker: "USR_RLS_CHK",
        "advisor-rm": "USR_RLS_ADVR",
    };

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    //To-Do temperory fix
    _preLoadStorage() {
        this.storageService.setdefaultSettings(defaults);
    }
    base_url = environment.base_url;
    constructor(
        private store: Store<fromAppReducers.State>,
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _platform: Platform,
        private http: HttpClient,
        private location: Location,
        private shared: SharedService,
        private storageService: StorageService,
        private ssoService: SSOService,
        private mainService: MainService,
        private router: ActivatedRoute,
        public dialog: MatDialog,
        private _route: Router
    ) {
        let role = this.storageService.role;
        this.defaultLang = this.storageService.language || "en";
        this.defaultLangs = this.storageService.languages || ["en"];
        const domain = environment.domainName;
        console.log(domain);
        let isLoggedIn = this.ssoService.isLoggedIn();

        if (!isLoggedIn) {
            if (
                this.location.path().indexOf("otp") == -1 &&
                this.location.path().indexOf("export") == -1
            ) {
                this.ssoService.obtainAccessToken();
            }
        } else {
            try {
                this.ssoService.getUserDetails().subscribe((claims) => {
                    // this.roleDetails = this.filteruserRoleType(
                    //     this.roleData[claims.role]
                    // );
                    console.log(claims, "claims app");
                    let tenant: any = claims.tenantId;
                    //let localeId: any = claims.locale;
                    let tenantName: any = claims.tenantName;
                    let role = claims.role;
                    //this.storageService.setItem("reviewType",claims.reviewType);
                    this.storageService.username = claims.username;
                    this.storageService.tenantId = tenant;
                    this.storageService.vendor = tenantName;
                    this.storageService.publicKey = "Z/q+NSsXd8b+r0uTl0q3Dg==";
                    // this.getPublicKey().subscribe((data) => {
                    //     this.storageService.setItem(
                    //         "publicKey",
                    //         data.data[0]
                    //     );
                    // });
                    console.log(
                        "this.storageService.publicKey::::" +
                            this.storageService.publicKey.length
                    );
                    console.log(
                        "this.storageService.publicKey::::" +
                            JSON.stringify(this.storageService.publicKey)
                    );
                    let accessRights =
                        claims.permissionsData.data[0] != undefined
                            ? claims.permissionsData.data[0].accessright
                            : "";
                    this.storageService.accessRights =
                        JSON.stringify(accessRights);
                    this.storageService.lang = "1";
                    this.storageService.language = "en";
                    this.storageService.role = this.storageService.role || role;
                    this.storageService.roleList = JSON.stringify(
                        claims.roleList
                    );
                    console.log("Accessbile profile", claims.accessibleProfile);
                    this.storageService.accessibleProfile =
                        claims.accessibleProfile;
                    console.log(
                        "Accessbile profile from storage:::::",
                        this.storageService.accessibleProfile
                    );

                    this.storageService.bookingLocations =
                        claims.bookingLocations;
                    this.storageService.regions = claims.regions;
                    this.storageService.cities = claims.cities;
                    this.storageService.branches = claims.branches;
                    this.storageService.customerSegment = JSON.stringify(
                        claims.customerSegment
                    );
                    this.storageService.expiryDate = claims.expiryDate;
                    this.storageService.phone = claims.phone;
                    this.storageService.reviewType = JSON.stringify(
                        claims.reviewType
                    );
                    this.storageService.status = claims.status;
                    this.storageService.accessToken = claims.accessToken;
                    console.log(this.storageService);
                    let agreed = this.storageService.getItem("agreed");
                    let path = this.location.path();
                    let excludeExport = path.includes("/export");
                    if (role != "dadmin" && agreed != "yes" && !excludeExport) {
                        this.termsAndCond();
                    }

                    this._translateService.currentLang = this.defaultLang;
                    this._translateService.use(this.defaultLang);
                    if (!this.storageService.vendor) {
                        this.storageService.vendor = "diligent";
                    }

                    this._translateService.currentLang = this.defaultLang;
                    this._translateService.use(this.defaultLang);
                    if (!this.storageService.vendor) {
                        this.storageService.vendor = "diligent";
                    }

                    /*       this.mainService.loadTranslationIntoApp()
                      .subscribe(
                        (data) =>{
                          this.mainService.setNavigation(role);
                        }
                      ) */
                    this.mainService.setNavigation(role);
                    if (this.storageService.tenantId == "undefined") {
                        this.storageService.tenantId = "0";
                    }
                    this.mainService
                        .getConfiguredStatus(this.storageService.tenantId)
                        .subscribe((data: any) => {
                            if (data.response) {
                                this.storageService.setItem(
                                    "isConfigured",
                                    true
                                );
                            } else {
                                this.storageService.setItem(
                                    "isConfigured",
                                    false
                                );
                            }
                            this.mainService.setNavigation(role);
                            this._translateService.use(
                                this.storageService.language
                            );
                            this.mainService.getLocales().subscribe((langs) => {
                                console.log(langs);
                                let defaultLangs = langs.map((item) => item.id);
                                this.storageService.setItem(
                                    "langMap",
                                    JSON.stringify(langs)
                                );
                                this.storageService.languages = defaultLangs;

                                this._translateService.addLangs(defaultLangs);
                                this.mainService
                                    .loadTranslationIntoApp(langs, role)
                                    .subscribe((data: any) => {
                                        this._fuseTranslationLoaderService.loadTranslations(
                                            ...data
                                        );
                                        this.mainService.loadedLocales.next(
                                            true
                                        );
                                    });
                            });
                        });
                    console.log(claims, "claims");
                });
            } catch (e) {
                console.log(e);
                /*                 this.storageService.tenantId = "1";
                this.storageService.lang = '1';
                this.storageService.language = 'en'; */
                // this.storageService.role = "tadmin";
                this.mainService.setNavigation(role);
            }

            //this.router.navigate(['home']);
        }

        // Use a language
        // this._translateService.use(this.defaultLang);

        console.log(["/onboard", "/login"].indexOf(this.location.path()));

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    getPublicKey(): Observable<any> {
        let url = `${this.base_url}/thirdparty/keycloak/getpublickey`;
        return this.http.get<any>(url);
    }

    // filteruserRoleType(mnemonicCodeStr: any) {
    //     // this.mainService.getRoleContents().subscribe(response => {
    //     //     let rolesContentRes = response[0];
    //     //     let rolesContentData = rolesContentRes["data"];
    //     //     let rtData = rolesContentData[0].content.filter(rr => rr.isDisabled === false && rr.mnemonicCode === mnemonicCodeStr);
    //     //     console.log(rtData, "user role types");
    //     //     if (rtData.length > 0) {
    //     //         return rtData.map(item => {
    //     //             this.storageService.setItem('roleId', item.id);
    //     //             this.storageService.setItem('roleDisplayOrder', item.displayOrder);
    //     //             return { roleTypeId: item.id, displayOrder: item.displayOrder };
    //     //         })
    //     //     }
    //     // })
    // }
    // async generateAesKey(): Promise<string> {
    //     try {
    //         const key = await window.crypto.subtle.generateKey(
    //             {
    //                 name: "AES-GCM",
    //                 length: 256,
    //             },
    //             true,
    //             ["encrypt", "decrypt"]
    //         );

    //         // Export the key as a hex-encoded string
    //         const keyBuffer = await window.crypto.subtle.exportKey("raw", key);
    //         const keyArray = Array.from(new Uint8Array(keyBuffer));
    //         const keyHex = keyArray
    //             .map((byte) => byte.toString(16).padStart(2, "0"))
    //             .join("");

    //         return keyHex;
    //     } catch (error) {
    //         console.error("Error generating AES key:", error);
    //         throw error;
    //     }
    // }
    showcase() {
        this._fuseSplashScreenService.show();
    }
    hideProgress() {
        this._fuseSplashScreenService.hide();
    }
    termsAndCond() {
        const dialogRef = this.dialog.open(TermsConditionsComponent, {
            width: "80%",
            disableClose: true,
            data: {},
        });

        dialogRef.afterClosed().subscribe((input) => {
            if (input) {
                this.storageService.setItem("agreed", input);
            }

            if (input == "no") {
                this.ssoService.logout();
            }
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        /*   this.shared.navigationToggle$.subscribe(
             (flag) => {
                 console.log(flag ,"onboarded")
                 if(flag == "onboarded"){
                     this._fuseConfigService.config
                     .pipe(takeUntil(this._unsubscribeAll))
                     .subscribe((config) => {
                         this.fuseConfig.layout.navbar.hidden = false;
                         this.fuseConfig.layout.toolbar.hidden = false;
                         this.storageService.role = 'admin'
                         window.location.reload();
                     })
               
                 }
             }
         )  */
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith("theme-")) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.storageService.removeItem("agreed");
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
