import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[libEnableScroll]'
})
export class EnableScrollDirective {

  constructor(
    private el?: ElementRef,
    private renderer?: Renderer2
  ) { 
    console.log("hello")
  }
  @HostListener('wheel', ['$event'])
  scrollFn(e){
    //console.log(this.el.nativeElement.querySelector('.outer').classList)
    if(!this.el.nativeElement.querySelector('.outer').classList.contains('scroll')){
   //   console.log(this.outerDiv.nativeElement.classList.contains('scroll'));
      this.renderer.addClass(this.el.nativeElement.querySelector('.outer'), 'scroll');
    } 
   
  }
  @HostListener('click', ['$event'])
  preventTable(e: Event){
    
    if(this.el.nativeElement.querySelector('.outer').classList.contains('scroll')){
      //console.log(this.el.nativeElement.querySelector('.outer'));
      this.renderer.removeClass(this.el.nativeElement.querySelector('.outer'), 'scroll');
    } 
    
    
  }
}
