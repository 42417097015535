import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CommonService {


  resetPasswordUrl: string = `${environment.base_url}/master/usermanagement/resetpwd`;
  constructor(
    private http: HttpClient
  ) { }
  resetPassword(data){  
    return this.http.post(this.resetPasswordUrl, {user: data});
  }
}
