import { NgModule } from "@angular/core";
import { MetaFormComponent } from "./meta-form/meta-form.component";
import { MetaFormsControlService } from "./services/meta-forms-control.service";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../material.module";
import { MetaSharedModule } from "../meta-shared/meta-shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { MetaFormsErrorsService } from "./services/meta-forms-errors.service";
import { MetaFormCompositeComponent } from "./meta-form-composite/meta-form-composite.component";
import { MetaFormConditionalComponent } from "./meta-form-conditional/meta-form-conditional.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
    declarations: [
        MetaFormComponent,
        MetaFormCompositeComponent,
        MetaFormConditionalComponent,
    ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        MaterialModule,
        MetaSharedModule,
        TranslateModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
    ],
    exports: [MetaFormComponent],
    providers: [MetaFormsControlService, MetaFormsErrorsService],
})
export class MetaFormsModule {}
