import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-shared',
  template: `
    <p>
      shared works!
    </p>
  `,
  styles: []
})
export class SharedComponent implements OnInit {

  constructor(private trans: TranslateService) { }

  ngOnInit() {
    this.trans.onLangChange.subscribe(
      data => {
        console.log("ijjj", data)
      }
    )
  }

}
