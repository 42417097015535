import { NgModule } from "@angular/core";
import { MetaFormsModule } from "./meta-forms/meta-forms.module";
import { MaterialModule } from "./material.module";
import { MetaAccordionModule } from "./meta-accordion/meta-accordion.module";
import { MetaTableModule } from "./meta-table";
import { MetaFieldsModule } from "./meta-fields";
import { MetaTabsModule } from "./meta-tabs";
import { MetaUploadModule } from "./meta-upload/meta-upload.module";
import { MetaTabsV2Module } from "./meta-tabs-v2";
import { MetaSharedModule } from "./meta-shared/meta-shared.module";
import { CaseManagementModule } from "./case-management-popup/case-management.module";

const modules = [
    MetaFormsModule,
    MetaAccordionModule,
    MetaTableModule,
    MetaFieldsModule,
    MetaTabsModule,
    MetaTabsV2Module,
    MetaUploadModule,
    MetaSharedModule,
    CaseManagementModule,
];

@NgModule({
    declarations: [],
    imports: [...modules, MaterialModule],
    exports: [...modules, MaterialModule],
})
export class MetaComponentsModule {}
