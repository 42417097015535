import { Component, OnInit } from "@angular/core";
import { navigation } from "app/navigation/navigation";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseNavigation } from "@fuse/types";
import * as _ from "lodash";
import { Router } from "@angular/router";
import { SSOService } from "../../oauth2/sso/sso.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { NgxSpinnerService } from "ngx-spinner";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { TranslateService } from "@ngx-translate/core";
import { MainService } from "../../services/main.service";
import { StorageService } from "dil-shared";
import { UtilService } from "../../../../projects/shared/src/public_api";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
    role: any;
    isLoggedIn: boolean = false;
    navigation: FuseNavigation[];
    isTenantAdmin: boolean = false;
    isConfigured: any;
    tenantId: string;
    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private ssoService: SSOService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private mainService: MainService,
        private storageService: StorageService,
        private utilService: UtilService,
        private router: Router
    ) {
        this.isLoggedIn = this.ssoService.isLoggedIn();
        console.log(this.isLoggedIn, "this.isLoggedIn");
        if (!this.isLoggedIn) {
            this.login();
            console.log("okkk");
        } else {
            let claims: any = this.ssoService.getUserClaims();
            console.log(claims.tenantid);
            if (claims.tenantid != undefined) {
                this.mainService
                    .getConfiguredStatus(claims.tenantid)
                    .subscribe((data) => {
                        const dataObject: { data: { isConfigure: string }[] } =
                            data as any;

                        if (
                            dataObject &&
                            dataObject.data &&
                            dataObject.data.length > 0
                        ) {
                            console.log(dataObject.data[0].isConfigure);
                            this.isConfigured = dataObject.data[0].isConfigure;
                            if (this.isConfigured == 0) {
                                this.configure();
                            }
                        } else {
                            console.log(
                                "Data is either undefined or not in the expected structure."
                            );
                        }
                    });
            }
        }
    }

    login() {
        this.ssoService.obtainAccessToken();
    }

    logout() {
        this.ssoService.logout();
    }

    ngOnInit() {
        this.mainService.getLocales().subscribe((langs) => {
            console.log(langs);
            let defaultLangs = langs.map((item) => item.id);
            this.storageService.setItem("langMap", JSON.stringify(langs));
            this.storageService.languages = defaultLangs;
            this.utilService.loadTanslations("home");
        });
        //this.isConfigured = !!this.storageService.getItem("isConfigured");
    }
    onboardTenant() {
        this.router.navigate(["onboard-tenant"]);
    }

    checkRole(role) {
        return this.storageService.role == role;
    }
    configure() {
        this.router.navigate(["onboard-tenant/onboard-option"]);
    }
}
interface homeScreenData {
    header: any;
    content: any;
    image: any;
}
