import { Injectable } from "@angular/core";
import { OAuthService, AuthConfig } from "angular-oauth2-oidc";
import {
    OAuthErrorEvent,
    OAuthSuccessEvent,
    OAuthInfoEvent,
} from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc";
import { authConfig } from "../oauth2.config";
import { forkJoin, Observable } from "rxjs";
import { environment } from "environments/environment";
import { StorageService } from "../../../../projects/shared/src/public_api";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT, Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { HomeComponent } from "app/main/home/home.component";

@Injectable()
export class SSOService {
    claims: any;
    base_url = environment.base_url;
    constructor(
        private oauthService: OAuthService,
        private storageServie: StorageService,
        private location: Location,
        private http: HttpClient,
        private _actRoute: ActivatedRoute
    ) {
        this.configureOauthService();
    }

    private configureOauthService() {
        let host = window.location.hostname;
        //let relamid:string[] = host.split(".");
        let issr = environment.sso.serverUrl + host.split(".")[0];
        const authConfigNew: AuthConfig = {
            issuer: issr,
            redirectUri: environment.sso.redirectUri,
            clientId: environment.sso.clientId,
            scope: environment.sso.scope,
            requireHttps: environment.sso.requireHttps,
            disableAtHashCheck: true,
            showDebugInformation: environment.sso.showDebugInformation,
            userinfoEndpoint: `${this.oauthService.issuer}/protocol/openid-connect/userinfo`,
        };

        this.oauthService.configure(authConfigNew);

        this.oauthService.events.subscribe((event) => {
            if (event instanceof OAuthSuccessEvent) {
                // Handle the OAuth success event here
                // console.log("OAuth success event:", event);
                // Perform further actions or redirect to a protected route
            } else if (event instanceof OAuthErrorEvent) {
                // Handle OAuth error event
                // console.log("Authentication error:", event.reason);
            } else if (event instanceof OAuthInfoEvent) {
                // Handle OAuth error event
                // console.log("Info event:", event.info);
            }
        });
        /** enable below validation only if jwks object is defined as part of oauthconfig obj */
        // this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this.oauthService.setStorage(sessionStorage);
        // console.log("After setting the storage");

        /** commented below because below resource is protected by some identity server ex: wso2 */
        this.oauthService.loadDiscoveryDocumentAndTryLogin();
        //console.log("After try logging in");

        //this.oauthService.tryLogin({});
    }

    public obtainAccessToken() {
        //console.log("Inside obtain access token");
        this.oauthService.initImplicitFlow();
    }

    public getUserName(): string {
        const accessToken = this.getAccessToken();
        const claims = this.getUserClaims();
        this.getUserInfo();
        return claims["sub"].split("@")[0];
    }

    getUserDetailsOld() {
        this.claims = this.getUserClaims();

        // console.log("this.claims.language"+this.claims.language);

        let locale;
        let tenant;
        let tenantId;
        let tenantName;
        let language;
        let tenantDetails = [];
        let username;
        let role;

        if (this.claims.region) {
            tenantDetails = this.claims.region.split("-");
            tenantId = tenantDetails[0];
            tenantName = tenantDetails[1].toLowerCase();
        }

        if (this.claims.website) language = this.claims.website.toLowerCase();
        role = "tadmin";
        if (this.claims.groups && this.claims.groups.length) {
            try {
                role = this.claims.groups.filter(
                    (item) =>
                        [
                            "t_admin",
                            "d_admin",
                            "maker",
                            "checker",
                            "checker_admin",
                        ].indexOf(item.toLowerCase()) > -1
                );
                role = role.map((item) => item.replace("_", "").toLowerCase());
            } catch (e) {
                console.error(e);
            }
        }

        username = this.claims["sub"];

        return { locale, username, tenantId, tenantName, role, language };
    }

    getUserDetails() {
        this.claims = this.getUserClaims();
        let accessToken: any;
        let username: any;
        let role: any;
        let roleList: any;
        let tenantId: any;
        let tenantName: any;
        let accessibleProfile: any;
        let bookingLocations: any;
        let regions: any;
        let cities: any;
        let branches: any;
        let customerSegment: any;
        let expiryDate: any;
        let phone: any;
        let reviewType: any;
        let branchSearch: any;
        let status: any;
        if (this.claims) {
            accessToken = this.getAccessToken();
            username = this.claims.preferred_username;
            role = this.claims.role[0];
            roleList = this.claims.role;
            this.storageServie.role = role;
            const permissions$ = this.getPermissionsByRole(role);
            tenantId = this.claims.tenantid;
            tenantName = this.claims.tenantname;
            this.storageServie.vendor = tenantName;
            accessibleProfile = this.claims.accessibleProfile;
            bookingLocations = this.claims.bookingLocations;
            regions = this.claims.regions;
            cities = this.claims.cities;
            branches = this.claims.branches;
            branchSearch = this.claims.branchSearch;
            customerSegment = this.claims.customerSegment || [];
            expiryDate = this.claims.expiryDate;
            phone = this.claims.phone;
            reviewType = this.claims.reviewType || [];
            status = this.claims.status;
            return forkJoin([permissions$]).pipe(
                map(([permissionsData]) => {
                    return {
                        username,
                        role,
                        roleList,
                        tenantId,
                        tenantName,
                        accessibleProfile,
                        bookingLocations,
                        regions,
                        cities,
                        branches,
                        customerSegment,
                        expiryDate,
                        phone,
                        reviewType,
                        branchSearch,
                        status,
                        accessToken,
                        permissionsData,
                    };
                })
            );
        } else {
            role = this.storageServie.getItem("role");
            const permissions$ = this.getPermissionsByRole(role);
            tenantId = this.storageServie.getItem("tenantId");
            tenantName = this.storageServie.getItem("vendor");
            return forkJoin([permissions$]).pipe(
                map(([permissionsData]) => {
                    return {
                        username,
                        role,
                        roleList,
                        tenantId,
                        tenantName,
                        accessibleProfile,
                        bookingLocations,
                        regions,
                        cities,
                        branches,
                        customerSegment,
                        expiryDate,
                        phone,
                        reviewType,
                        branchSearch,
                        status,
                        accessToken,
                        permissionsData,
                    };
                })
            );
        }
    }

    public getUserInfo(): string {
        const idToken = this.oauthService.getIdToken();
        return typeof idToken["sub"] !== "undefined"
            ? idToken["sub"].toString()
            : "";
    }

    getPermissionsByRole(roleName: string): Observable<any> {
        this.storageServie.setItem("accessToken", this.getAccessToken());
        let url = `${this.base_url}/thirdparty/keycloak/getallpermissionsbyrole/${roleName}`;
        return this.http.get<any>(url);
    }

    public getAccessToken(): string {
        let token = this.oauthService.getAccessToken();
        return this.oauthService.getAccessToken();
    }

    public getUserClaims(): object {
        return this.oauthService.getIdentityClaims();
    }

    public languageWrapper(selectedLang) {
        let lang;
        if (selectedLang == "1") {
            lang = "en";
        } else if (selectedLang == "2") {
            lang = "cn";
        } else if (selectedLang == "3") {
            lang = "fr";
        }
        return lang;
    }
    public userRoleWrapper(tenant) {
        let lang;
        if (tenant == "1") {
            lang = "hdfc";
        } else if (tenant == "2") {
            lang = "icici";
        } else if (tenant == "3") {
            lang = "default";
        }
        return lang;
    }

    public saveUserClaims() {
        let claims = this.oauthService.getIdentityClaims();
    }

    public isLoggedIn(): boolean {
        let locationUrl = this.location.path().split("/")[1];
        // alert(locationUrl);
        let token = this.oauthService.getAccessToken();
        //console.log("Token:::::", token);
        if (
            this.oauthService.getAccessToken() === null &&
            locationUrl != "onboarding"
        ) {
            //console.log("SSO Service Is logged in:::::If");

            return false;
        }

        return true;
    }

    public checkSession(): void {
        const token = this.oauthService.getAccessToken(); // Get the stored access token
        const userInfoUrl = this.oauthService.userinfoEndpoint;
        if (!token) {
            console.warn("No access token found, logging out...");
            return;
        }

        this.http
            .get(userInfoUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .pipe(
                catchError((error) => {
                    if (error.status === 401) {
                        console.warn("Session expired. Logging out...");
                        this.oauthService.logOut();
                    }
                    throw error;
                })
            )
            .subscribe();
    }

    public logout(): void {
        this.oauthService.logOut();

        //   let claims: any = this.getUserClaims();
        //   if(claims){
        //     let sid = claims.sid, devUrl = environment.dev_url, keycloakUri = environment.sso.serverUrl,
        //     logoutUrl = `${keycloakUri}/commonauth?commonAuthLogout=true&type=oidc&sessionDataKey=${sid}&commonAuthCallerPath=${devUrl}&relyingParty=NewCDD`;

        // document.location.href = `${keycloakUri}/commonauth?commonAuthLogout=true&type=oidc&sessionDataKey=${sid}&commonAuthCallerPath=${devUrl}&relyingParty=NewCDD`;
        //   }
        sessionStorage.clear();
        this.storageServie.clear();
        //    this.oauthService.logOut();
        //window.location.reload();
    }
}
