import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Location } from "@angular/common";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

import { navigation } from "app/navigation/navigation";
import {
    SharedService,
    ResetPasswordComponent,
} from "../../../../../projects/shared/src/public_api";
import { MainService } from "../../../services/main.service";
import { StorageService } from "dil-shared";
import { Router } from "@angular/router";
import { SSOService } from "../../../oauth2/sso/sso.service";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { RootComponent, LOCALE_RESOLVED } from "shared-store";
import { Store } from "@ngrx/store";
import { environment } from "environments/environment";
import { SwitchRolesComponent } from "./switch-roles/switch-roles.component";

@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent
    extends RootComponent
    implements OnInit, OnDestroy
{
    username: string = "";
    areLanguagesLoaded: Promise<boolean>;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;

    tenantName: string;

    selectedLanguage: any;
    userStatusOptions: any[];
    isOnboard: boolean = true;
    // Private
    host: any;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private sharedService: SharedService,
        private mainService: MainService,
        private storageService: StorageService,
        private ssoService: SSOService,
        private location: Location,
        private router: Router,
        public dialog: MatDialog,
        private _store: Store<any>
    ) {
        super();
        router.events.subscribe((val) => {
            if (location.path().indexOf("/onboard") > 0) {
                this.isOnboard = true;
            } else {
                this.isOnboard = false;
            }
            //     console.log(location.path(), val);
        });
        // Set the defaults
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50",
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107",
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336",
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD",
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161",
            },
        ];

        this.languages = [
            {
                id: "vn",
                title: "Vietnamese",
                flag: "vn",
                localeId: 5,
            },
            {
                id: "en",
                title: "English",
                flag: "en",
                localeId: 1,
            },
            {
                id: "tr",
                title: "Turkish",
                flag: "tr",
                localeId: 2,
            },
            {
                id: "in",
                title: "Hindi",
                flag: "in",
                localeId: 3,
            },
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    areLangsLoaded: Promise<boolean>;
    logout() {
        this.ssoService.logout();
    }

    openRoleSwitchModal() {
        let roles = JSON.parse(this.storageService.getItem("roleList"));
        const currentRole = this.storageService.getItem("role");
        roles = roles.filter((item) => item != currentRole);
        console.log("Open ROle");
        this.dialog.open(SwitchRolesComponent, {
            width: "250px",
            data: { roles: roles },
        });
    }

    ngOnInit(): void {
        this.tenantName = this.storageService.vendor;

        this.host = window.location.hostname;
        this._store.dispatch(
            LOCALE_RESOLVED({
                payload: { data: this.languages[0] },
            })
        );

        this.username = this.storageService.username;
        console.log("kkkkkkkkkkkkkkkkkkkkkk");
        console.log("langMap", this.storageService.getItem("langMap"));
        this.mainService.loadedLocales.subscribe((flag) => {
            if (flag) {
                let langs = this.storageService.languages;
                let langMap = JSON.parse(
                    this.storageService.getItem("langMap")
                );
                this.username = this.storageService.username;
                this.languages = langMap;
                console.log(
                    "this._translateService.currentLang",
                    this._translateService.currentLang
                );
                this.selectedLanguage = _.find(langMap, {
                    id: this._translateService.currentLang,
                });
                console.log(langs, "langs");
                this.areLanguagesLoaded = Promise.resolve(true);
            }
        });
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === "top";
                this.rightNavbar = settings.layout.navbar.position === "right";
                //   this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        //  this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});
    }

    resetPassword() {
        let url = `${environment.ip_address}/realms/${
            this.host.split(".")[0]
        }/protocol/openid-connect/auth?client_id=cdd&redirect_uri=${
            environment.sso.redirectUri
        }&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD&prompt=UPDATE_PASSWORD`;
        //window.open(url);
        window.location.href = url;
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }


     /**
     * Toggle sidebar opened status
     */
     toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleFold();
    }
    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        console.log(lang, "langgggggggggggggggg");
        this.storageService.lang = lang.localeId;
        this.storageService.language = lang.id;
        this.selectedLanguage = lang;
        this.sharedService.lang$.next(lang);
        // Use the selected language for translations
        this._translateService.use(lang.id);
        this._store.dispatch(
            LOCALE_RESOLVED({
                payload: { data: lang },
            })
        );
    }
}
