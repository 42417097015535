import { Component, OnInit } from "@angular/core";

@Component({
    selector: "lib-meta-table",
    templateUrl: "./meta-table.component.html",
    styleUrls: ["./meta-table.component.scss"],
})
export class MetaTableComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
