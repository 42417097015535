import { Component, OnInit } from '@angular/core';
import { UtilService } from 'projects/shared/src/public_api';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private utilService: UtilService) { 
    this.utilService.loadTanslations("error");
  }

  ngOnInit() {
  }

}
