import {
    MetaReducer,
    ActionReducer
} from '@ngrx/store';
import {
    environment
} from 'environments/environment';

export interface State {}

export function debug(reducer: ActionReducer < any > ): ActionReducer < any > {
    return function (state, action) {
        console.log('state', state);
        console.log('action', action);
        return reducer(state, action);
    };
}
console.log("=== env variable", environment.production)
export const metaReducers: MetaReducer < State > [] = (environment && !environment.production) ? [debug] : [];
console.log("== meta red val ====", metaReducers)