import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";

import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { environment } from "environments/environment";

export class TransformCategoryLocale {
    id: number;
    name: string = "";
    description: string = "";
    parentCategoryId: number;
    parentContentId: number;

    constructor(obj: any, langId) {
        this.id = obj.id;

        if (obj && obj.localeData && obj.localeData.length) {
            let locale = obj.localeData.filter(
                (item) => item.localeId == langId
            );

            if (locale.length) {
                this.name = locale[0].name;
                this.description = locale[0].description;
            }
        }

        this.parentCategoryId = obj.parentCategoryId;
        this.parentContentId = obj.parentContentId;
    }
}

@Injectable({
    providedIn: "root",
})
export class UtilService {
    individualId: number;
    boOthers: any;
    constructor(
        private storageService: StorageService,
        private http: HttpClient,
        private fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {}

    compareById(a, b) {
        if (a.id < b.id) {
            return -1;
        }
        if (a.id > b.id) {
            return 1;
        }
        return 0;
    }

    compareByProperty(a, b, prop) {
        if (a[prop] < b[prop]) {
            return -1;
        }
        if (a[prop] > b[prop]) {
            return 1;
        }
        return 0;
    }

    deepClone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    get englishLang() {
        let langList: any[] = JSON.parse(
            this.storageService.getItem("langMap") || "[]"
        );
        let lang = langList.filter((item) => item.id == "en");
        if (lang.length) {
            return lang[0].localeId;
        }
        return 1;
    }

    getLangIdByLang(lang) {
        let langList: any[] = JSON.parse(
            this.storageService.getItem("langMap") || "[]"
        );
        let language = langList.filter((item) => item.id == lang);
        if (language.length) {
            return language[0].localeId;
        }
        return 1;
    }
    getContentEngNameById(data, id) {
        let content: any = "";
        if (data && data.length) {
            content = data
                .filter((ele) => ele.id == id)
                .map(
                    (item) =>
                        new TransformCategoryLocale(item, this.englishLang)
                );
        }
        console.log(content);
        if (!content.length) {
            return "";
        }

        return content[0].name;
    }

    getItemDetailsByLang(data) {
        let temp = [];
        if (data && data.localeData && data.localeData.length) {
            temp = data.localeData.filter(
                (i) => i.localeId == this.storageService.lang
            );
        }
        if (!temp.length) {
            let lang = this.englishLang;
            if (data && data.localeData && data.localeData.length) {
                temp = data.localeData.filter((i) => i.localeId == lang);
            }
        }
        return temp;
    }

    getEngLocale(data) {
        let temp = [];
        let lang = this.englishLang;
        temp = data.localeData.filter((i) => i.localeId == lang);
        return temp;
    }

    getOnlyEngLocale(data) {
        let temp: any = "";
        let lang = this.englishLang;
        if (data && data.localeData) {
            let tempVal = data.localeData.filter((i) => i.localeId == lang);
            if (tempVal.length) {
                temp = tempVal[0].name;
            }
        }
        return temp;
    }

    loadTanslations(page) {
        let vendor = this.storageService.vendor || "uatncb-tenant";
        console.log(vendor, "vendorvendorvendorvendor");
        let languages = this.storageService.languages;
        let langsObs = languages.map((item) => {
            let commonObservable = this.http.get(
                `/assets/tenants/${vendor}/common/${item}.json`
            );

            let labelsObservable = this.http.get(
                `/assets/tenants/${vendor}/${page}/labels/${item}.json`
            );

            return forkJoin([commonObservable, labelsObservable]).pipe(
                map(([commonData, labelsData]) => {
                    let obj1 = commonData["data"];
                    let obj2 = labelsData["data"];
                    let merge = { ...obj2, ...obj1 };
                    return { lang: item, data: merge };
                })
            );
        });
        forkJoin(langsObs).subscribe((res: any[]) => {
            this.fuseTranslationLoaderService.loadTranslations(...res);
        });
    }

    getOnlyname(content) {
        let details = this.getItemDetailsByLang(content);
        let name = this.getOnlyEngLocale(content);
        if (details && details.length) {
            name = details[0].name;
        }
        return name;
    }
    prepareLangData(item, unwrap = false) {
        let temp = this.getItemDetailsByLang(item);
        if (temp && temp.length) {
            item.name = temp[0].name;
            item.text = temp[0].name;
            if (unwrap) {
                item.ownershipTypeId = temp[0].name;
            }
            //    item.DLevelName = temp[0].DLevelName

            item.description = temp[0].description;
        }
    }
    onLangChange(data: any = [], cond = -1, unwrap = false) {
        data.forEach((item, i) => {
            if (i == cond) {
            } else {
                this.prepareLangData(item, unwrap);
            }
        });
    }
    getItemDetailsByLanglist(data) {
        let temp = [];
        if (data && data.localeData && data.localeData.length) {
            temp = data.localeData.filter(
                (i) => i.localeId == this.storageService.lang
            );
        }
        if (!temp.length) {
            let lang = this.englishLang;
            temp = data.localeData.filter((i) => i.localeId == lang);
        }
        return temp;
    }
    prepareLangDataList(item) {
        let temp = this.getItemDetailsByLanglist(item);
        if (temp && temp.length) {
            item.fieldVal = temp[0].name;
        }
    }
    onLangChangeList(data, cond = -1) {
        data.forEach((item, i) => {
            if (i == cond) {
            } else {
                this.prepareLangDataList(
                    item[0]["IDV_DOC_CHECKLIST_LABEL_FIELD_NAME"]
                );
            }
        });
    }

    getPopupMsg(translations, key) {
        if (translations && translations[this.storageService.language]) {
            return translations[this.storageService.language][key];
        }
    }

    getDistinctListByKeyValue(data = [], key, lang = "1") {
        console.log(key, "keyyyyyyyyyyyyyy");
        let uniqueIds = Array.from(new Set(data.map((item) => item[key])));
        let temp = [];
        for (let uniqueId of uniqueIds) {
            let tempArray = data.filter(
                (item) => item[key] == uniqueId && item.localeId == lang
            );
            temp.push(tempArray[0]);
        }
        return temp;
    }
    extractUniqueArray(list) {
        return Array.from(new Set(list));
    }
}
