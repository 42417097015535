import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { UtilService } from "../../../../shared/src/public_api";
import {
    FormGroup,
    FormControl,
    Validators,
    FormGroupDirective,
} from "@angular/forms";
import { TriggerEventService } from "../trigger-event.service";
import { StorageService } from "dil-shared";
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, Observable } from "rxjs";
import {
    startWith,
    map,
    debounceTime,
    distinctUntilChanged,
    tap,
    switchMap,
    finalize,
    filter,
    skipWhile,
} from "rxjs/operators";
import { isEqual } from "lodash";
import { ContentLocale } from "projects/document-checklist/src/lib/idv-checklist-model/idv-checklist.model";

@Component({
    selector: "lib-customer-trigger-event",
    templateUrl: "./customer-trigger-event.component.html",
    styleUrls: ["./customer-trigger-event.component.css"],
})
export class CustomerTriggerEventComponent implements OnInit {
    isCorporate: boolean = true;
    localeId: any = this.storageService.lang;
    triggerEventTyLang: any;
    triggerEventTypes: any[] = [];
    languageSubscription: Subscription;
    nameOptions: CustomerDetails[] = [];
    idOptions: CustomerDetails[] = [];
    cachedValue: any;
    idCachedValue: any;
    selectedOptionLang: CustomerDetails;
    hideMessage: boolean;
    triggerEventSelIndex: any = [];
    bookingLocationData: any = [];
    bookingData: any;
    contentDataList: any = [];
    AllBookingData: any = [];
    orgTypeId: number;
    bookingContentIdMap: any;
    newBookingLocationData: any = [];
    selectedBookingLocationsExisting: any = [];
    selectedBookingLocationsNew: any = [];
    selectedNew: number[] = [];
    bookingLocationsSelected: any;
    countryIdSelected: any;
    countryIncorporationSelected: any;

    COIData: any = [];
    masterDataContent: any;
    anyForCoiTemp: any;
    countryAny: any;
    coiLabel: any = [];
    nationalityLang: any;
    nationalityArray: any[] = [];
    nationalityLabel: any;

    filteredOptions: Observable<any[]>;
    filteredIdOptions: Observable<any[]>;
    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

    triggerEventForm: FormGroup = new FormGroup({
        id: new FormControl(),
        customerName: new FormControl("", Validators.required),
        customerId: new FormControl("", Validators.required),
        bookingLocation: new FormControl("", Validators.required),
        countryIncorporation: new FormControl("", Validators.required),
        triggerEventTy: new FormControl("", Validators.required),
        reason: new FormControl("", Validators.required),
    });

    nameControl = new FormControl();
    idControl = new FormControl();

    constructor(
        private router: Router,
        private utilService: UtilService,
        private triggerEventService: TriggerEventService,
        private spinner: NgxSpinnerService,
        private snackBar: MatSnackBar,
        private translateService: TranslateService,
        private storageService: StorageService
    ) {}

    ngOnInit(): void {
        this.router.url == "/trigger-event/corporate-trigger-event"
            ? (this.isCorporate = true)
            : (this.isCorporate = false);

        this.utilService.loadTanslations("trigger-event");

        this.languageSubscription =
            this.translateService.onLangChange.subscribe((lang) => {
                this.localeId = this.storageService.lang;
                this.triggerEventTypes = this.filterTriggerEventTypes(
                    this.triggerEventTyLang
                );
                this.nationalityArray = this.filterNationality(
                    this.nationalityLang
                );
                this.nationalityLabel = this.nationalityArray[0].name;
                if (this.triggerEventSelIndex != undefined) {
                    let triggerEventLangChanged: any = [];
                    for (let k = 0; k < this.triggerEventSelIndex.length; k++) {
                        triggerEventLangChanged.push(
                            this.triggerEventTypes[this.triggerEventSelIndex[k]]
                                .name
                        );
                    }
                    this.triggerEventForm.controls["triggerEventTy"].setValue(
                        triggerEventLangChanged
                    );
                }
                if (this.selectedOptionLang != undefined) {
                    this.setBookingLocationData(this.selectedOptionLang);
                }
                this.utilService.onLangChange(this.COIData);
            });

        if (!this.isCorporate) {
            this.triggerEventService.getNationalityData().subscribe((coi) => {
                let countryId = coi[0]["data"] || [];
                let countryData = coi[1]["data"];
                this.masterDataContent = countryData;

                for (let i of countryId) {
                    if (!i.isDeleted)
                        this.loadIdvCountry(i.countryId, countryData, i.id);
                }

                this.COIData.sort(this.sortByName);
                if (this.anyForCoiTemp) {
                    this.COIData.unshift(this.anyForCoiTemp);
                }
            },(err)=>{
                this.spinner.hide();
                this.snackBar.open(
                    this.utilService.getPopupMsg(
                        this.translateService.translations,
                        "TRIGGER_EVENT_ERROR_IDV_OR_MASTER_DATA_SERVER_DOWN"
                    ),
                    "",
                    { duration: 3000 }
                );
            });
        }

        this.getNationalityMasterData();
        this.getTriggerEventTypes();
        this.getCustomerIdDtls();
        this.getCustomerNameDtls();
    }

    loadIdvCountry(id, countryData, ConId) {
        let tempCountryData = countryData
            .filter((item) => item.id == id)
            .map((item) => {
                item.corporationId = ConId;
                return new ContentLocale(
                    item,
                    this.storageService.lang || "1",
                    ConId
                );
            });
        //let engName = this.utilService.getOnlyEngLocale(tempCountryData[0]);
        if (!tempCountryData[0].isDisabled) {
            if (tempCountryData[0].mnemonicCode != "CONT_ANY") {
                this.COIData.push(tempCountryData[0]);
            } else {
                this.anyForCoiTemp = tempCountryData[0];
            }
        }
        let tempAnyValue;
        countryData
            .filter((item) => item.id == id)
            .map((item) => {
                item.corporationId = ConId;
                tempAnyValue = new ContentLocale(
                    item,
                    this.storageService.lang,
                    ConId
                );
                let engName = this.utilService.getOnlyEngLocale(tempAnyValue);
                if (tempCountryData[0].mnemonicCode == "CONT_ANY")
                    this.countryAny = tempAnyValue;
            });
    }

    getCustomerIdDtls() {
        this.idControl.valueChanges
            .pipe(
                debounceTime(2000),
                distinctUntilChanged(),
                filter((val) => {
                    if (val == null || !(val.length > 0)) {
                        return false;
                    }
                    if (val == this.idCachedValue) {
                        return false;
                    }
                    this.idCachedValue = val;
                    return true;
                }),
                // filter((query: string) => query?.length > 0),
                tap(() => {
                    this.idOptions = [];
                    this.spinner.show();
                }),
                switchMap((value: string) =>
                    this.triggerEventService
                        .searchCustomerDetails(
                            this.isCorporate ? 1 : 2,
                            "customerId",
                            value
                        )
                        .pipe(
                            finalize(() => {
                                this.spinner.hide();
                            })
                        )
                )
            )
            .subscribe((response) => {
                let customerRes: any[] = response[0];
                if (customerRes["data"].length > 0) {
                    customerRes["data"].forEach((element) => {
                        let customerDetails: CustomerDetails =
                            new CustomerDetails();
                        customerDetails.id = element.id;
                        customerDetails.customerId = element.applicationId;
                        customerDetails.name = element.customerName;
                        customerDetails.orgTypeId = element.orgTypeId;
                        let bookingLocIds: string[] = [];
                        let bookLocRespData: any = element.bookingLocations;
                        for (let j = 0; j < bookLocRespData.length; j++) {
                            bookingLocIds.push(bookLocRespData[j]);
                        }
                        customerDetails.bookingLocations = bookingLocIds;
                        customerDetails.coi = element.countryOfIncorporation;
                        this.idOptions.push(customerDetails);
                    });
                } else {
                    this.clearForm();
                    this.snackBar.open(
                        this.utilService.getPopupMsg(
                            this.translateService.translations,
                            "TRIGGER_EVENT_ERROR_NO_RECORD_EXIST"
                        ),
                        "",
                        { duration: 3000 }
                    );
                    this.spinner.hide();
                }
            },(err)=>{
                this.snackBar.open(
                    this.utilService.getPopupMsg(
                        this.translateService.translations,
                        "TRIGGER_EVENT_ERROR_ONBOARD_SERVER_DOWN"
                    ),
                    "",
                    { duration: 3000 }
                );
                this.spinner.hide();
            });
    }

    getCustomerNameDtls() {
        this.nameControl.valueChanges
            .pipe(
                debounceTime(2000),
                distinctUntilChanged(),
                filter((val) => {
                    if (val == null || !(val.trim().length > 0)) {
                        return false;
                    }
                    const equal = isEqual(this.cachedValue, val);
                    this.cachedValue = val;
                    return !equal;
                }),
                // filter((query: string) => query?.length > 0),
                tap(() => {
                    this.nameOptions = [];
                    this.spinner.show();
                }),
                switchMap((value: string) =>
                    this.triggerEventService
                        .searchCustomerDetails(
                            this.isCorporate ? 1 : 2,
                            "customerName",
                            value
                        )
                        .pipe(
                            finalize(() => {
                                this.spinner.hide();
                            })
                        )
                )
            )
            .subscribe((response) => {
                let customerRes: any[] = response[0];
                if (customerRes["data"].length > 0) {
                    customerRes["data"].forEach((element) => {
                        let customerDetails: CustomerDetails =
                            new CustomerDetails();
                        customerDetails.id = element.id;
                        customerDetails.customerId = element.applicationId;
                        customerDetails.name = element.customerName;
                        customerDetails.orgTypeId = element.orgTypeId;
                        let bookingLocIds: string[] = [];
                        let bookLocRespData: any = element.bookingLocations;
                        for (let j = 0; j < bookLocRespData.length; j++) {
                            bookingLocIds.push(bookLocRespData[j]);
                        }
                        customerDetails.bookingLocations = bookingLocIds;
                        customerDetails.coi = element.countryOfIncorporation;
                        this.nameOptions.push(customerDetails);
                    });
                } else {
                    this.clearForm();
                    this.snackBar.open(
                        this.utilService.getPopupMsg(
                            this.translateService.translations,
                            "TRIGGER_EVENT_ERROR_NO_RECORD_EXIST"
                        ),
                        "",
                        { duration: 3000 }
                    );
                    this.spinner.hide();
                }
            },(err)=>{
                this.snackBar.open(
                    this.utilService.getPopupMsg(
                        this.translateService.translations,
                        "TRIGGER_EVENT_ERROR_ONBOARD_SERVER_DOWN"
                    ),
                    "",
                    { duration: 3000 }
                );
                this.spinner.hide();
            });
    }

    onTriggerEventTyChange(event) {
        this.triggerEventSelIndex = [];
        for (let c = 0; c < event.value.length; c++) {
            for (let i = 0; i < this.triggerEventTypes.length; i++) {
                if (this.triggerEventTypes[i].name == event.value[c]) {
                    this.triggerEventSelIndex.push(i);
                    break;
                }
            }
        }
    }

    clearForm() {
        this.formGroupDirective.resetForm();
        this.triggerEventSelIndex = undefined;
        this.selectedOptionLang = undefined;
        this.nameOptions = [];
        this.idOptions = [];
        this.cachedValue = undefined;
        this.idCachedValue = undefined;
        this.AllBookingData = [];
    }

    getSelectedNmaeOption(event, selectedOption) {
        this.idControl.setValue(selectedOption.customerId, {
            emitEvent: false,
        });
        this.cachedValue = selectedOption.name;
        this.setBookingLocationData(selectedOption);
        this.setNationality(selectedOption);
        this.selectedOptionLang = selectedOption;
        this.triggerEventForm.get("id").setValue(selectedOption.id);
    }

    getSelectedIdOption(event, selectedOption) {
        this.nameControl.setValue(selectedOption.name, { emitEvent: false });
        this.idCachedValue = selectedOption.customerId;
        this.setBookingLocationData(selectedOption);
        this.setNationality(selectedOption);
        this.selectedOptionLang = selectedOption;
        this.triggerEventForm.get("id").setValue(selectedOption.id);
    }

    private setNationality(selectedOption) {
        let nationalityId: any = selectedOption.coi;

        for (let a = 0; a < this.COIData.length; a++) {
            if (this.COIData[a].id == nationalityId) {
                this.countryIncorporationSelected = this.COIData[a].corpId;
            }
        }

        if (this.isCorporate) {
            this.triggerEventForm.removeControl("countryIncorporation");
        }
    }

    private setBookingLocationData(selectedOption) {
        this.spinner.show();
        let bookingLocIds: any[] = selectedOption.bookingLocations;
        let globalValTemp;
        this.orgTypeId = selectedOption.orgTypeId;
        this.triggerEventService
            .getBookingLocationData(this.orgTypeId)
            .subscribe((response) => {
                let bookingData = response[0]["data"];
                this.bookingData = bookingData;
                this.contentDataList = response[1]["data"];
                let tempbookingCountry = response[1]["data"];
                this.bookingLocationData = bookingData;
                this.orgTypeId = this.bookingLocationData[0].id;
                let tempLocation =
                    this.bookingLocationData[0].bookingLocations.filter(
                        (loc) => !loc.isNotApplicable
                    );
                this.bookingContentIdMap = tempLocation.map(
                    (item) =>
                        new Object({
                            id: item.id,
                            contentId: item.contentId,
                            countryId: item.countryId,
                        })
                );
                this.AllBookingData = [];
                //this.transformCountry(bookingData);
                for (let loc of tempLocation) {
                    let tempCotent = tempbookingCountry
                        .filter((i) => i.id == loc.contentId)
                        .map(
                            (item) =>
                                new ContentLocale(
                                    item,
                                    this.storageService.lang || "1"
                                )
                        );
                    let engName = this.utilService.getOnlyEngLocale(
                        tempCotent[0]
                    );
                    if (tempCotent[0].mnemonicCode != "CONT_GLOBAL") {
                        this.AllBookingData.push({
                            id: tempCotent[0].id,
                            name: tempCotent[0].name,
                            localeData: tempCotent[0].localeData,
                            contentId: loc.contentId,
                            bookingId: loc.id,
                            countryId: loc.countryId,
                            displayOrder: tempCotent[0].displayOrder,
                        });
                    } else {
                        globalValTemp = {
                            id: tempCotent[0].id,
                            name: tempCotent[0].name,
                            localeData: tempCotent[0].localeData,
                            contentId: loc.contentId,
                            bookingId: loc.id,
                            countryId: loc.countryId,
                            displayOrder: tempCotent[0].displayOrder,
                        };
                    }
                }

                this.AllBookingData.sort(this.sortByName);
                if (globalValTemp) {
                    this.AllBookingData.unshift(globalValTemp);
                }
                this.newBookingLocationData = this.utilService.deepClone(
                    this.AllBookingData
                );
            },(err)=>{
                this.spinner.hide();
                this.snackBar.open(
                    this.utilService.getPopupMsg(
                        this.translateService.translations,
                        "TRIGGER_EVENT_ERROR_IDV_OR_MASTER_DATA_SERVER_DOWN"
                    ),
                    "",
                    { duration: 3000 }
                );
            })
            .add(() => {
                this.bookingLocationsSelected = this.AllBookingData.filter(
                    (item) => bookingLocIds.includes(item.id)
                ).map((item) => item.bookingId);

                this.countryIdSelected = this.bookingContentIdMap
                    .filter((item) =>
                        this.bookingLocationsSelected.includes(item.id)
                    )
                    .map((item) => item.countryId);
                this.isSelectedTagIsCallbackNew(this.countryIdSelected);
                this.spinner.hide();
            });
    }

    isSelectedTagIsCallback(value) {
        this.selectedBookingLocationsExisting = value;
        this.newBookingLocationData.forEach(
            (item) => (item.disabled = value.includes(item.countryId))
        );
        this.selectedNew = this.newBookingLocationData
            .filter((item) => !item.disabled)
            .map((item) => item.countryId)
            .filter((item) => this.selectedBookingLocationsNew.includes(item));
        this.isSelectedTagIsCallbackNew(this.selectedNew);
    }

    isSelectedTagIsCallbackNew(value) {
        this.selectedBookingLocationsNew = value;
    }

    coiSelected(coi) {
        this.coiLabel = this.COIData.filter((item) => item.documentId == coi);
    }

    private sortByName(a, b) {
        return a.displayOrder - b.displayOrder;
    }

    getTriggerEventTypes() {
        this.triggerEventService.getTriggerEventTypes().subscribe(
            (response) => {
                let reviewRes = response[0];
                if (reviewRes["data"].length > 0) {
                    this.triggerEventTyLang = reviewRes;
                    this.triggerEventTypes =
                        this.filterTriggerEventTypes(reviewRes);
                } else {
                    this.snackBar.open(
                        this.utilService.getPopupMsg(
                          this.translateService.translations,
                          response[0]["response"]["message"]
                      ),
                         "", {duration:3000});
                    this.spinner.hide();
                }
            },
            (err) => {
                this.snackBar.open(
                    this.utilService.getPopupMsg(
                        this.translateService.translations,
                        "TRIGGER_EVENT_ERROR_MASTER_DATA_SERVER_DOWN"
                    ),
                    "",
                    { duration: 3000 }
                );
                this.spinner.hide();
            }
        );
    }

    getNationalityMasterData() {
        this.triggerEventService.getMasterDataFields().subscribe(
            (response) => {
                let nationalityRes = response[0];
                if (nationalityRes["data"].length > 0) {
                    this.nationalityLang = nationalityRes;
                    this.nationalityArray =
                        this.filterNationality(nationalityRes);
                    this.nationalityLabel = this.nationalityArray[0].name;
                } else {
                    this.snackBar.open(
                        this.utilService.getPopupMsg(
                          this.translateService.translations,
                          response[0]["response"]["message"]
                      ),
                         "", {duration:3000});
                    this.spinner.hide();
                }
            },
            (err) => {
                this.snackBar.open(
                    this.utilService.getPopupMsg(
                        this.translateService.translations,
                        "TRIGGER_EVENT_ERROR_MASTER_DATA_SERVER_DOWN"
                    ),
                    "",
                    { duration: 3000 }
                );
                this.spinner.hide();
            }
        );
    }

    filterTriggerEventTypes(rtData) {
        let rtyData = rtData.data[0].content.filter(
            (rr) => rr.isDisabled === false
        );
        if (rtyData.length > 0) {
            return rtyData.map((item) => {
                let localeData = item.localeData.filter(
                    (x) => x.localeId === this.localeId
                );
                return {
                    id: item.id,
                    name: this.utilService.getOnlyname(item),
                };
            });
        } else {
            this.snackBar.open(
                this.utilService.getPopupMsg(
                    this.translateService.translations,
                    "TRIGGER_EVENT_ERROR_NO_RECORD_EXIST"
                ),
                "",
                { duration: 3000 }
            );
            this.spinner.hide();
        }
    }

    filterNationality(nData) {
        let ntyData = nData.data[0].content.filter(
            (rr) =>
                rr.isDisabled === false && rr.mnemonicCode === "FILD_IND_NAT"
        );

        if (ntyData.length > 0) {
            return ntyData.map((item) => {
                return {
                    id: item.id,
                    name: this.utilService.getOnlyname(item),
                };
            });
        } else {
            this.snackBar.open(
                this.utilService.getPopupMsg(
                    this.translateService.translations,
                    "TRIGGER_EVENT_ERROR_NO_RECORD_EXIST"
                ),
                "",
                { duration: 3000 }
            );
            this.spinner.hide();
        }
    }

    filterSearch(searchedText, value) {
        return (
            searchedText &&
            !value.toLowerCase().includes(searchedText.toLowerCase())
        );
    }

    triggerCustomerDtls() {
        this.spinner.show();
        let coiConfiguredVal: any = 0;
        let applicationNum = this.triggerEventForm.get("customerId").value;
        let triggerEventTy = this.triggerEventForm.get("triggerEventTy").value;
        let triggerReason = this.triggerEventForm.get("reason").value;
        let customerId = this.triggerEventForm.get("id").value;
        let bookingLocation =
            this.triggerEventForm.get("bookingLocation").value;
        let assignedTo = this.storageService.username;
        let orgTypeConfigId = this.orgTypeId;
        let finalBookingData: any = {};
        // let finalBookingData = this.bookingContentIdMap
        //   .filter((temp) => bookingLocation.includes(temp.countryId))
        //   .map((item) => new Object({ id: item.id, countryId: item.countryId }));
        for (let item of this.bookingContentIdMap.filter((temp) =>
            bookingLocation.includes(temp.countryId)
        )) {
            var key = item.id;
            finalBookingData[key] = item.countryId;
        }
        let bookingLocationConfigIds = Object.keys(finalBookingData).map(
            (key) => parseInt(key)
        );
        if (!this.isCorporate) {
            coiConfiguredVal = this.triggerEventForm.get(
                "countryIncorporation"
            ).value;
        }

        const body = {
            id: customerId,
            applicationId: applicationNum,
            triggerEventTypes: triggerEventTy,
            triggerEventReason: triggerReason,
            bookingLocations: bookingLocationConfigIds,
            bookingLocationsAndContId: finalBookingData,
            countryOfIncorporation: coiConfiguredVal,
            assignedUser: assignedTo,
            orgTypeId: orgTypeConfigId,
        };
        // this.triggerEventService.triggerCustomerDetails(body).subscribe(
        //     (data) => {
        //         this.clearForm();
        //         let triggerResp = data[0];
        //         let isSucessArray = triggerResp["data"];
        //         let msg = this.utilService.getPopupMsg(
        //             this.translateService.translations,
        //             "TRIGGER_EVENT_ERROR_NO_RECORD_EXIST"
        //         );
        //         if (isSucessArray.length == 0) {
        //             let errorRes = triggerResp["response"];
        //             msg = "Failed: " + errorRes.message;
        //             console.log(errorRes, "errorRes", errorRes.message);
        //         } else if (isSucessArray[0].isCustProfileCreated) {
        //             msg = this.utilService.getPopupMsg(
        //                 this.translateService.translations,
        //                 "TRIGGER_EVENT_SUCCESS_TRIGGER"
        //             );
        //         }
        //         this.spinner.hide();
        //         this.snackBar.open(msg, "", { duration: 3000 });
        //         this.spinner.hide();
        //     },
        //     (err) => {
        //         this.clearForm();
        //         this.hideMessage = true;
        //         this.snackBar.open(
        //             this.utilService.getPopupMsg(
        //                 this.translateService.translations,
        //                 "TRIGGER_EVENT_ERROR_NO_RECORD_EXIST"
        //             ),
        //             "",
        //             { duration: 3000 }
        //         );
        //         this.spinner.hide();
        //         setTimeout(() => {
        //             this.hideMessage = false;
        //         }, 3000);
        //     }
        // );
        this.triggerEventService.triggerCustomerDetails(body).subscribe(
            (data: any) => {
                this.clearForm();
                let triggerResp = data[0];
                let isSucessArray = triggerResp["data"];
                this.spinner.hide();
                this.snackBar.open(data[0].data[0].errorMessage, "", {
                    duration: 3000,
                });
            },
            (err) => {
                this.clearForm();
                this.snackBar.open(
                    this.utilService.getPopupMsg(
                        this.translateService.translations,
                        "TRIGGER_EVENT_ERROR_ONBOARD_SERVER_DOWN"
                    ),
                    "",
                    { duration: 3000 }
                );
                this.spinner.hide();
            }
        );
    }
}

export class CustomerDetails {
    id: any;
    name: string;
    customerId: string;
    bookingLocations: string[];
    bookingLocationsAndContId: any;
    coi: any;
    orgTypeId: any;
}
