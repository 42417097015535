import { Pipe, PipeTransform } from "@angular/core";
interface LocaleData {
    description: string;
    id: number;
    localeId: number;
    name: string;
    parentCategoryId: number;
    parentContentId: number;
    tenantId: number;
}
@Pipe({
    name: "localeLabel",
})
export class LocaleLabelPipe implements PipeTransform {
    transform(localeData: LocaleData[] = [], ...args): string {
        if (!localeData || !localeData.length) return null;
        const selectedLocaleId: number = args[0];
        if (selectedLocaleId) {
            const label = localeData
                .filter((locale) => locale.localeId === selectedLocaleId)
                .pop() || null;
            return label && label.name;
        } else {
            console.warn("Locale Id is not available");
            return null;
        }
    }
}
