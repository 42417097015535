import { Component, OnInit } from "@angular/core";
import { navigation } from "app/navigation/navigation";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseNavigation } from "@fuse/types";
import * as _ from "lodash";
import { Router } from "@angular/router";
import { SSOService } from "../../oauth2/sso/sso.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { NgxSpinnerService } from "ngx-spinner";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { TranslateService } from "@ngx-translate/core";
import { MainService } from "../../services/main.service";
import { StorageService } from "dil-shared";
import { UtilService } from "../../../../projects/shared/src/public_api";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
    role: any;
    isLoggedIn: boolean = false;
    navigation: FuseNavigation[];
    isTenantAdmin: boolean = false;
    isConfigured: any;
    tenantId: string;
    accessRights: any;
    currentRole: string | null;
    filteredCards: any;
    roleCard: boolean = false;
    accessRightsPresent = true;

    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private ssoService: SSOService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private mainService: MainService,
        private storageService: StorageService,
        private utilService: UtilService,
        private router: Router
    ) {
        this.isLoggedIn = this.ssoService.isLoggedIn();
        console.log(this.isLoggedIn, "this.isLoggedIn");
        this.utilService.loadTanslations("navigation");
        this.utilService.loadTanslations("home");
        if (!this.isLoggedIn) {
            this.login();
            console.log("okkk");
        } else {
            let claims: any = this.ssoService.getUserClaims();
            console.log(claims.tenantid);
            this.utilService.loadTanslations("navigation");
            this.utilService.loadTanslations("home");
            if (claims.tenantid != undefined) {
                this.mainService
                    .getConfiguredStatus(claims.tenantid)
                    .subscribe((data) => {
                        const dataObject: { data: { isConfigure: string }[] } =
                            data as any;

                        if (
                            dataObject &&
                            dataObject.data &&
                            dataObject.data.length > 0
                        ) {
                            console.log(dataObject.data[0].isConfigure);
                            this.isConfigured = dataObject.data[0].isConfigure;
                            if (this.isConfigured == 0) {
                                this.configure();
                            }
                        } else {
                            console.log(
                                "Data is either undefined or not in the expected structure."
                            );
                        }
                    });
            }
        }
    }

    login() {
        this.ssoService.obtainAccessToken();
    }

    logout() {
        this.ssoService.logout();
    }

    ngOnInit() {
        setTimeout(() => {
            this.mainService.getLocales().subscribe((langs) => {
                console.log(langs);
                let defaultLangs = langs.map((item) => item.id);
                this.storageService.setItem("langMap", JSON.stringify(langs));
                this.storageService.languages = defaultLangs;

                const accessRightsString = localStorage.getItem("accessRights");
                this.accessRights = accessRightsString
                    ? new Set(JSON.parse(accessRightsString))
                    : new Set();
                this.filteredCards = this.cards.filter((item) =>
                    this.accessRights.has(item.accessKey)
                );
                if (this.filteredCards.length <= 0) {
                    this.accessRightsPresent = false;
                }
                this.currentRole = (localStorage.getItem("role") || "")
                    .toUpperCase()
                    .replace(/[^A-Z0-9]/g, "_");
                this.roleCard = true;
            });
        }, 1500);
        //this.isConfigured = !!this.storageService.getItem("isConfigured");
    }
    onboardTenant() {
        this.router.navigate(["onboard-tenant"]);
    }

    checkRole(role) {
        return this.storageService.role == role;
    }
    configure() {
        this.router.navigate(["onboard-tenant/onboard-option"]);
    }

    cards = [
        {
            accessKey: "MANAGEMASTERCATEGORIES_VIEW",
            icon: "assets/icons/navigation/black/Manage Master Categories_32 X 32.png",
            label: "NAVIGATION_LABEL_MANAGE_MASTER_CATEGORIES",
            description: "HOME_LABEL_ADMIN_MASTER_CATEGORIES",
        },
        {
            accessKey: "MANAGEMASTERDATA_VIEW",
            icon: "assets/icons/navigation/black/Manage Master Data_32 X 32.png",
            label: "NAVIGATION_LABEL_MANAGE_MASTER_DATA",
            description: "HOME_LABEL_ADMIN_MASTER_DATA",
        },
        {
            accessKey: "COUNTRY-CONFIGURATION_VIEW",
            icon: "assets/icons/navigation/black/Country_32 X 32.png",
            label: "NAVIGATION_LABEL_COUNTRY",
            description: "HOME_LABEL_ADMIN_COUNTRY",
        },
        {
            accessKey: "IDV-CONFIGURATION_VIEW",
            icon: "assets/icons/navigation/black/IDV Information_32 X 32.png",
            label: "NAVIGATION_LABEL_IDV_INFORMATION",
            description: "HOME_LABEL_ADMIN_IDV_INFORMATION",
        },
        {
            accessKey: "IDV-DOCUMENTATION_VIEW",
            icon: "assets/icons/navigation/black/IDV Documentation_32 X 32.png",
            label: "NAVIGATION_LABEL_IDV_DOCUMENTATION",
            description: "HOME_LABEL_ADMIN_IDV_DOCUMENTATION",
        },
        {
            accessKey: "NOTIFICATION-CONFIGURATION_VIEW",
            icon: "assets/icons/navigation/black/Notification Engine_32 X 32.png",
            label: "NAVIGATION_LABEL_NOTIFICATION",
            description: "HOME_LABEL_ADMIN_NOTIFICATION_CONFIG_MSG",
        },
        {
            accessKey: "WORKFLOW-CONFIGURATION_VIEW",
            icon: "assets/icons/navigation/black/Workflow Configuration_32 X 32.png",
            label: "NAVIGATION_LABEL_WORKFLOW",
            description: "HOME_LABEL_ADMIN_WORKFLOW",
        },
        {
            accessKey: "REPORTS_VIEW",
            icon: "assets/icons/navigation/white/IDV Documentation_32 X 32.png",
            label: "NAVIGATION_LABEL_REPORTS",
            description: "HOME_LABEL_ADMIN_REPORTS",
        },
        {
            accessKey: "SEARCH-CUSTOMER_VIEW",
            icon: "assets/icons/navigation/black/Search Customer_2_32 X 32.png",
            label: "NAVIGATION_LABEL_SEARCH_CUSTOMER",
            description: "HOME_LABEL_MAKER_SEARCH_CUSTOMER",
        },
        {
            accessKey: "POOL_VIEW",
            icon: "assets/icons/navigation/black/Pool_32 X 32.png",
            label: "NAVIGATION_LABEL_POOL",
            description: "HOME_LABEL_POOL",
        },
        {
            accessKey: "QUEUE_VIEW",
            icon: "assets/icons/navigation/black/My Queue_32 X 32.png",
            label: "NAVIGATION_LABEL_MY_QUEUE",
            description: "HOME_LABEL_MY_QUEUE",
        },
        {
            accessKey: "ONBOARDING-CUSTOMER_VIEW",
            icon: "assets/icons/navigation/black/Onboard Customer_32 X 32.png",
            label: "NAVIGATION_LABEL_ONBOARD_CUSTOMER",
            description: "HOME_LABEL_MAKER_ONBOARD_CUSTOMER",
        },
        {
            accessKey: "ACCESS-MANAGEMENT_VIEW",
            icon: "assets/icons/navigation/black/Manage Master Data_32 X 32.png",
            label: "NAVIGATION_LABEL_ACCESSS_MANAGEMENT",
            description: "HOME_LABEL_ACCESSS_MANAGEMENT",
        },
        {
            accessKey: "USER-MANAGEMENT_VIEW",
            icon: "assets/icons/navigation/black/Workflow Configuration_32 X 32.png",
            label: "NAVIGATION_LABEL_USER_MANAGEMENT",
            description: "HOME_LABEL_USER_MANAGEMENT",
        },
        {
            accessKey: "LICENSE-AGREEMENT_VIEW",
            icon: "assets/icons/navigation/white/collaboration.png",
            label: "NAVIGATION_LABEL_LICENSE_AGREEMENT",
            description: "HOME_LABEL_LICENSE_AGREEMENT",
        },
    ];
}
interface homeScreenData {
    header: any;
    content: any;
    image: any;
}
