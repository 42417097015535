import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { StorageService } from "projects/shared/src/public_api";

@Component({
    selector: "app-switch-roles",
    templateUrl: "./switch-roles.component.html",
    styleUrls: ["./switch-roles.component.scss"],
})
export class SwitchRolesComponent implements OnInit {
    constructor(
        private router: Router,
        private storageService: StorageService,
        public dialogRef: MatDialogRef<SwitchRolesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {}

    onCancel() {
        this.dialogRef.close();
    }

    async switchRole(role: any) {
        this.storageService.role = role;
        await this.router.navigate(["/home"]);
        location.reload();
    }
}
