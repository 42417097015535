import { Injectable } from '@angular/core';
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CaseManagementService {

  constructor(private http:HttpClient) {

   }

  private orgType = `${environment.base_url}/config/idv/latest-orgtype`;
  _countryURL = `${environment.base_url}/master/category/content?codes=CONT`;
  
  getFinalResults(caseSystemID){
    return this.http.get(`${environment.locale_local_url}/profile/customer/${caseSystemID}/finalNameScreeningResult`);
  }

  getDetailsForRecord(referenceId){
    return this.http.get(`${environment.locale_local_url}/profile/customer/${referenceId}/detailedView`);
  }

  resolveRecords(caseSystemId,requestBody){
    return this.http.put(`${environment.locale_local_url}/profile/customer/${caseSystemId}/resolution`,requestBody);
  }

  getFinalResultsForMultipleCaseSystemIds(listOfCaseSystemIds){
    return this.http.post(`${environment.locale_local_url}/profile/customer/finalNameScreeningResult`,listOfCaseSystemIds);
  }

  getBulkResolve(resolveList){
    return this.http.post(`${environment.locale_local_url}/profile/customer/resolution`,resolveList);
  }

  nameScreeningForMultipleRecords(listData){
    return this.http.post(`${environment.locale_local_url}/profile/customer/multipleNamescreening`,listData);
  }

  nameScreeningForExistingCase(listOfCaseSystemIds) {
    return this.http.post(`${environment.locale_local_url}/profile/customer/existingMultipleNamescreening`,listOfCaseSystemIds);
  }

  triggerCustomerDetails(body) {
    let trigger_customer_url: string = `${environment.base_url}/profile/review/trigger_event/create`;
    let response1 = this.http.post(trigger_customer_url, body);
    return forkJoin([response1]);
  }

  searchCustomerDetails(segType, key, value) {
    let search_customer_url: string = `${environment.base_url}/profile/review/trigger_event/search/${segType}?${key}=${value}`;
    let response1 = this.http.get<any[]>(search_customer_url);
    return forkJoin([response1]);
  }

  getBookingLocationData(id) {
    return forkJoin(
      this.http.get<any[]>(`${this.orgType}/${id}`),
      this.http.get<any>(this._countryURL)
    );
  }

}
