import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  private base_url = environment.base_url;

  constructor(private http: HttpClient) { 
    
  }

  releaseLock(customerId){
    let release_lock_url = `${this.base_url}/profile/releaseLock/${customerId}`;
    return this.http.get<any[]>(release_lock_url);
  }
}
