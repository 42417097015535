import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { keyValuesToMap } from "@angular/flex-layout/extended/typings/style/style-transforms";
import {
    FormGroup,
    FormArray,
    FormControl,
    Validators,
    ValidationErrors,
    ValidatorFn,
    AbstractControl,
} from "@angular/forms";
import { AccessRights } from "projects/onboarding/src/lib/corporate/constants/access-permission.constants";
import { MetaFormsControlService } from "../services/meta-forms-control.service";
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";

export const MY_FORMATS = {
    parse: {
        dateInput: "LL",
    },
    display: {
        dateInput: "DD-MM-YYYY",
        monthYearLabel: "YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "YYYY",
    },
};

@Component({
    selector: "lib-meta-form",
    templateUrl: "./meta-form.component.html",
    styleUrls: ["./meta-form.component.css"],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class MetaFormComponent implements OnInit {
    // @Input() field: MetaField<string>;
    @Input() field: any;
    @Input() form: FormGroup;
    @Input() localeId: number;
    @Input() disabled: any;
    @Input() isComponent: any;
    @Input() rolePermissions: AccessRights;
    @Output() saveUpdateData = new EventEmitter<{ updatedData }>();
    @Output() fieldBlur = new EventEmitter<{
        event: any;
        field: any;
        formValue: any;
        index: number;
    }>();
    @Output() nameScreeningFieldChanged = new EventEmitter<{ changed }>();
    @Output() dedupeField = new EventEmitter<{
        fieldInfo;
        checked;
    }>();
    @Input() dedupe = false;
    previousChildValue = "0";
    childControl = "";
    conditionalFieldListItems: any;
    private bookingLocationId;
    private bookingLocationLocale;
    private selectedItem;
    private uniqueBookingLocation = [];
    private blItems = [];
    private blId: any;
    private uniqueBL = [];
    private dataSet = [];
    conditionalFields: any;
    conditionalForm: FormGroup;
    constructor(private _mfcs: MetaFormsControlService) {}
    ngOnInit() {
        this.field.options = this.field?.options.filter(
            (ele) =>
                ele?.mnemonicCode !== "CONT_GLOBAL" &&
                ele?.mnemonicCode !== "CONT_ANY"
        );
        console.log("Disabled value in ngOnInit:", this.disabled);
        console.log("This tab Permissions ", this.rolePermissions);
        this.conditionalFieldListItems =
            this.field.items[0].conditionalFieldListItems;
        if (this.field.conditional && this.field.items[0].value) {
            this.updateConditionalFields(this.field.items[0].value);
        }
        if (this.isComponent != "DUE") {
            this.field.items.forEach((element) => {
                this.uniqueBL = Array.from(
                    new Set(this.uniqueBL.concat(element.bookingLocationId.id))
                );
            });
            this.uniqueBL = this.uniqueBL.filter(function (element) {
                return element !== undefined;
            });
            if (this.uniqueBL[0] != undefined) {
                const field = this.field.items.map((fieldData, index) => {
                    if (
                        this.uniqueBL[0] == fieldData.bookingLocationId.id &&
                        index > 0
                    ) {
                        return Object.assign({}, fieldData, {
                            addFlag: true,
                        });
                    } else {
                        return fieldData;
                    }
                });
                this.field.items = field;
            }
        } else {
            this.field.items.forEach((element) => {
                this.uniqueBL = Array.from(
                    new Set(this.uniqueBL.concat(element.bookingLocationId.id))
                );
            });

            this.uniqueBL = this.uniqueBL.filter(function (element) {
                return element !== undefined;
            });

            if (this.uniqueBL[0] != undefined) {
                const field = this.field.items.map((fieldData, index) => {
                    // console.log(fieldData);
                    if (
                        fieldData.id != null &&
                        (fieldData.bookingLocationId.id != null ||
                            fieldData.bookingLocationId != "")
                    ) {
                        if (
                            this.uniqueBL[0] ==
                                fieldData.bookingLocationId.id &&
                            index > 0
                        ) {
                            // console.log(fieldData, "fielddata");
                            if (fieldData.composite == false) {
                                this.items.removeAt(index);
                                let formWork = new FormGroup({
                                    [fieldData.id]: this.field.isMandatory
                                        ? new FormControl(
                                              {
                                                  value: fieldData.value,
                                                  disabled:
                                                      this.rolePermissions
                                                          .readOnly,
                                              },
                                              [
                                                  Validators.required,
                                                  this.customValidator(
                                                      this.field.dataTypeId
                                                  ),
                                              ]
                                          )
                                        : new FormControl(
                                              {
                                                  value: fieldData.value,
                                                  disabled:
                                                      this.rolePermissions
                                                          .readOnly,
                                              },
                                              [
                                                  this.customValidator(
                                                      this.field.dataTypeId
                                                  ),
                                              ]
                                          ),
                                });
                                this.dataSet.push(formWork);
                                return Object.assign({}, fieldData, {
                                    addFlag: true,
                                });
                            } else if (
                                fieldData.conditional.items[0].conditional
                            ) {
                                this.items.removeAt(index);
                                const { conditionalFieldListItems = [] } =
                                    fieldData;
                                const conditionalArray =
                                    conditionalFieldListItems.map((field) => {
                                        const {
                                            conditionalDetails: {
                                                additionalConfig: [config] = [
                                                    { isMandatory: true },
                                                ],
                                            } = {},
                                        } = field;

                                        return new FormGroup({
                                            [field.id]: config.isMandatory
                                                ? new FormControl(
                                                      {
                                                          value: field.value,
                                                          disabled:
                                                              this
                                                                  .rolePermissions
                                                                  .readOnly,
                                                      },
                                                      [
                                                          Validators.required,
                                                          this.customValidator(
                                                              config.dataTypeId
                                                          ),
                                                      ]
                                                  )
                                                : new FormControl(
                                                      {
                                                          value: field.value,
                                                          disabled:
                                                              this
                                                                  .rolePermissions
                                                                  .readOnly,
                                                      },
                                                      [
                                                          this.customValidator(
                                                              config.dataTypeId
                                                          ),
                                                      ]
                                                  ),
                                        });
                                        //this.dataSet.push(formWork);
                                    });

                                let formWork = new FormGroup({
                                    [fieldData.id]: new FormArray(
                                        conditionalArray
                                    ),
                                });
                                this.dataSet.push(formWork);

                                return Object.assign({}, fieldData, {
                                    addFlag: true,
                                });
                            } else {
                                this.items.removeAt(index);
                                const { compositeFieldListItems = [] } =
                                    fieldData;
                                const compositeArray =
                                    compositeFieldListItems.map((field) => {
                                        const {
                                            compositeDetails: {
                                                additionalConfig: [config] = [
                                                    { isMandatory: true },
                                                ],
                                            } = {},
                                        } = field;

                                        return new FormGroup({
                                            [field.id]: config.isMandatory
                                                ? new FormControl(
                                                      {
                                                          value: field.value,
                                                          disabled:
                                                              this
                                                                  .rolePermissions
                                                                  .readOnly,
                                                      },
                                                      [
                                                          Validators.required,
                                                          this.customValidator(
                                                              config.dataTypeId
                                                          ),
                                                      ]
                                                  )
                                                : new FormControl(
                                                      {
                                                          value: field.value,
                                                          disabled:
                                                              this
                                                                  .rolePermissions
                                                                  .readOnly,
                                                      },
                                                      [
                                                          this.customValidator(
                                                              config.dataTypeId
                                                          ),
                                                      ]
                                                  ),
                                        });
                                        //this.dataSet.push(formWork);
                                    });

                                let formWork = new FormGroup({
                                    [fieldData.id]: new FormArray(
                                        compositeArray
                                    ),
                                });
                                this.dataSet.push(formWork);

                                return Object.assign({}, fieldData, {
                                    addFlag: true,
                                });
                            }
                        } else {
                            if (fieldData.composite == false) {
                                this.items.removeAt(index);
                                let formWork = new FormGroup({
                                    [fieldData.id]: this.field.isMandatory
                                        ? new FormControl(
                                              {
                                                  value: fieldData.value,
                                                  disabled:
                                                      this.rolePermissions
                                                          .readOnly,
                                              },
                                              [
                                                  Validators.required,
                                                  this.customValidator(
                                                      this.field.dataTypeId
                                                  ),
                                              ]
                                          )
                                        : new FormControl(
                                              {
                                                  value: fieldData.value,
                                                  disabled:
                                                      this.rolePermissions
                                                          .readOnly,
                                              },
                                              [
                                                  this.customValidator(
                                                      this.field.dataTypeId
                                                  ),
                                              ]
                                          ),
                                });
                                this.dataSet.push(formWork);
                                return fieldData;
                            } else if (fieldData.conditional == true) {
                                this.items.removeAt(index);
                                const { conditionalFieldListItems = [] } =
                                    fieldData;
                                const conditionalArray =
                                    conditionalFieldListItems.map((field) => {
                                        const {
                                            conditionalDetails: {
                                                additionalConfig: [config] = [
                                                    { isMandatory: true },
                                                ],
                                            } = {},
                                        } = field;
                                        return new FormGroup({
                                            [field.id]: config.isMandatory
                                                ? new FormControl(
                                                      {
                                                          value: field.value,
                                                          disabled:
                                                              this
                                                                  .rolePermissions
                                                                  .readOnly,
                                                      },
                                                      [
                                                          Validators.required,
                                                          this.customValidator(
                                                              config.dataTypeId
                                                          ),
                                                      ]
                                                  )
                                                : new FormControl(
                                                      {
                                                          value: field.value,
                                                          disabled:
                                                              this
                                                                  .rolePermissions
                                                                  .readOnly,
                                                      },
                                                      [
                                                          this.customValidator(
                                                              config.dataTypeId
                                                          ),
                                                      ]
                                                  ),
                                        });
                                        //this.dataSet.push(formWork);
                                    });
                                let formWork = new FormGroup({
                                    [fieldData.id]: new FormArray(
                                        conditionalArray
                                    ),
                                });
                                this.dataSet.push(formWork);
                                return fieldData;
                            } else {
                                this.items.removeAt(index);
                                const { compositeFieldListItems = [] } =
                                    fieldData;
                                const compositeArray =
                                    compositeFieldListItems.map((field) => {
                                        const {
                                            compositeDetails: {
                                                additionalConfig: [config] = [
                                                    { isMandatory: true },
                                                ],
                                            } = {},
                                        } = field;
                                        return new FormGroup({
                                            [field.id]: config.isMandatory
                                                ? new FormControl(
                                                      {
                                                          value: field.value,
                                                          disabled:
                                                              this
                                                                  .rolePermissions
                                                                  .readOnly,
                                                      },
                                                      [
                                                          Validators.required,
                                                          this.customValidator(
                                                              config.dataTypeId
                                                          ),
                                                      ]
                                                  )
                                                : new FormControl(
                                                      {
                                                          value: field.value,
                                                          disabled:
                                                              this
                                                                  .rolePermissions
                                                                  .readOnly,
                                                      },
                                                      [
                                                          this.customValidator(
                                                              config.dataTypeId
                                                          ),
                                                      ]
                                                  ),
                                        });
                                        //this.dataSet.push(formWork);
                                    });
                                let formWork = new FormGroup({
                                    [fieldData.id]: new FormArray(
                                        compositeArray
                                    ),
                                });
                                this.dataSet.push(formWork);
                                return fieldData;
                            }
                        }
                    } else if (
                        fieldData.id == null &&
                        (fieldData.bookingLocationId.id != null ||
                            fieldData.bookingLocationId != "")
                    ) {
                        if (fieldData.composite == false) {
                            this.items.removeAt(index);
                            var tempid = "BL:" + fieldData.bookingLocationId.id;
                            let formWork = new FormGroup({
                                [tempid]: this.field.isMandatory
                                    ? new FormControl(
                                          {
                                              value: "",
                                              disabled:
                                                  this.rolePermissions.readOnly,
                                          },
                                          [
                                              Validators.required,
                                              this.customValidator(
                                                  this.field.dataTypeId
                                              ),
                                          ]
                                      )
                                    : new FormControl(
                                          {
                                              value: "",
                                              disabled:
                                                  this.rolePermissions.readOnly,
                                          },
                                          [
                                              this.customValidator(
                                                  this.field.dataTypeId
                                              ),
                                          ]
                                      ),
                            });
                            this.dataSet.push(formWork);
                            return Object.assign({}, fieldData, {
                                id: "BL:" + fieldData.bookingLocationId.id,
                            });
                        } else if (fieldData.conditional == true) {
                            this.items.removeAt(index);
                            var tempid = "BL:" + fieldData.bookingLocationId.id;
                            const { conditionalFieldListItems = [] } =
                                fieldData;
                            const conditionalArray =
                                conditionalFieldListItems.map((field) => {
                                    const {
                                        conditionalDetails: {
                                            additionalConfig: [config] = [
                                                { isMandatory: true },
                                            ],
                                        } = {},
                                    } = field;

                                    return new FormGroup({
                                        null: config.isMandatory
                                            ? new FormControl(
                                                  {
                                                      value: "",
                                                      disabled:
                                                          this.rolePermissions
                                                              .readOnly,
                                                  },
                                                  [
                                                      Validators.required,
                                                      this.customValidator(
                                                          config.dataTypeId
                                                      ),
                                                  ]
                                              )
                                            : new FormControl(
                                                  {
                                                      value: "",
                                                      disabled:
                                                          this.rolePermissions
                                                              .readOnly,
                                                  },
                                                  [
                                                      this.customValidator(
                                                          config.dataTypeId
                                                      ),
                                                  ]
                                              ),
                                    });
                                    //this.dataSet.push(formWork);
                                });

                            let formWork = new FormGroup({
                                [tempid]: new FormArray(conditionalArray),
                            });
                            this.dataSet.push(formWork);

                            return Object.assign({}, fieldData, {
                                id: "BL:" + fieldData.bookingLocationId.id,
                            });
                        } else {
                            this.items.removeAt(index);
                            var tempid = "BL:" + fieldData.bookingLocationId.id;
                            const { conditionalFieldListItems = [] } =
                                fieldData;
                            const conditionalArray =
                                conditionalFieldListItems.map((field) => {
                                    const {
                                        conditionalDetails: {
                                            additionalConfig: [config] = [
                                                { isMandatory: true },
                                            ],
                                        } = {},
                                    } = field;

                                    return new FormGroup({
                                        null: config.isMandatory
                                            ? new FormControl(
                                                  {
                                                      value: "",
                                                      disabled:
                                                          this.rolePermissions
                                                              .readOnly,
                                                  },
                                                  [
                                                      Validators.required,
                                                      this.customValidator(
                                                          config.dataTypeId
                                                      ),
                                                  ]
                                              )
                                            : new FormControl(
                                                  {
                                                      value: "",
                                                      disabled:
                                                          this.rolePermissions
                                                              .readOnly,
                                                  },
                                                  [
                                                      this.customValidator(
                                                          config.dataTypeId
                                                      ),
                                                  ]
                                              ),
                                    });
                                    //this.dataSet.push(formWork);
                                });

                            let formWork = new FormGroup({
                                [tempid]: new FormArray(conditionalArray),
                            });
                            this.dataSet.push(formWork);

                            return Object.assign({}, fieldData, {
                                id: "BL:" + fieldData.bookingLocationId.id,
                            });
                        }
                    } else if (
                        fieldData.id == null &&
                        (fieldData.bookingLocationId.id == null ||
                            fieldData.bookingLocationId == "")
                    ) {
                        return fieldData;
                    }
                });

                this.field.items = field;
                this.items.controls = this.dataSet;
            }
        }
        this.field.items.forEach((element) => {
            this.blItems = Array.from(
                new Set(this.blItems.concat(element.bookingLocationId.id))
            );
        });
        this.blItems = this.blItems.filter(function (element) {
            return element !== undefined;
        });
    }
    get items(): FormArray {
        return this.form.get([this.field.id]) as FormArray;
    }
    get controls(): FormGroup[] {
        // return this.items.controls as FormGroup[];
        return this.items ? (this.items.controls as FormGroup[]) : [];
    }
    get newFormControl() {
        var count = 0;
        var ItemData = [];

        const compositeFieldListItemsData = [];

        if (this.bookingLocationId != null) {
            let tempid = "BL:" + this.blId + ":" + this.uuidv4();
            this.field.items.map((fieldData, index) => {
                this.uniqueBookingLocation.map((x) => {
                    if (
                        this.selectedItem.compositeFieldListItems.length == 0 &&
                        this.selectedItem.conditionalFieldListItems.length ==
                            0 &&
                        this.blId == x.bookingLocationId.id
                    ) {
                        const itemData = Object.assign({}, fieldData, {
                            id: tempid,
                            bookingLocationId: x.bookingLocationId,
                            addFlag:
                                this.bookingLocationId.id ===
                                x.bookingLocationId.id
                                    ? true
                                    : false,
                        });
                        const count = this.field.items.filter(
                            (item) => item.id == tempid
                        );
                        if (count.length == 0) this.field.items.push(itemData);
                    } else if (
                        this.selectedItem.compositeFieldListItems.length != 0 &&
                        this.blId == x.bookingLocationId.id
                    ) {
                        const itemData = Object.assign({}, fieldData, {
                            id: tempid,
                            bookingLocationId: x.bookingLocationId,
                            addFlag:
                                this.bookingLocationId.id ===
                                x.bookingLocationId.id
                                    ? true
                                    : false,
                        });
                        const count = this.field.items.filter(
                            (item) => item.id == tempid
                        );
                        if (count.length == 0) this.field.items.push(itemData);
                    } else if (
                        this.selectedItem.conditionalFieldListItems.length !=
                            0 &&
                        this.blId == x.bookingLocationId.id
                    ) {
                        const itemData = Object.assign({}, fieldData, {
                            id: tempid,
                            bookingLocationId: x.bookingLocationId,
                            addFlag:
                                this.bookingLocationId.id ===
                                x.bookingLocationId
                                    ? true
                                    : false,
                        });
                        const count = this.field.items.filter(
                            (item) => item.id == tempid
                        );
                        if (count.length == 0) this.field.items.push(itemData);
                    }
                });
            });
            // console.log(this.field.items,"datafdgsagd")
            if (this.selectedItem.compositeFieldListItems.length == 0) {
                return new FormGroup({
                    [tempid]: this.field.isMandatory
                        ? new FormControl("", [
                              Validators.required,
                              this.customValidator(this.field.dataTypeId),
                          ])
                        : new FormControl("", [
                              this.customValidator(this.field.dataTypeId),
                          ]),
                });
            } else {
                const { compositeFieldListItems = [] } = this.selectedItem;
                const compositeArray = compositeFieldListItems.map((field) => {
                    const {
                        compositeDetails: {
                            additionalConfig: [config] = [
                                { isMandatory: true },
                            ],
                        } = {},
                    } = field;

                    return new FormGroup({
                        null: config.isMandatory
                            ? new FormControl("", [
                                  Validators.required,
                                  this.customValidator(config.dataTypeId),
                              ])
                            : new FormControl("", [
                                  this.customValidator(config.dataTypeId),
                              ]),
                    });
                });
                return new FormGroup({
                    [tempid]: new FormArray(compositeArray),
                });
            }
        } else {
            const { compositeFieldListItems = [] } = this.field.items[0];
            const compositeArray = compositeFieldListItems.map((field) => {
                const {
                    compositeDetails: {
                        additionalConfig: [config] = [{ isMandatory: true }],
                    } = {},
                } = field;

                return new FormGroup({
                    null: config.isMandatory
                        ? new FormControl("", [
                              Validators.required,
                              this.customValidator(config.dataTypeId),
                          ])
                        : new FormControl("", [
                              this.customValidator(config.dataTypeId),
                          ]),
                });
            });

            if (compositeFieldListItems.length) {
                return new FormGroup({ null: new FormArray(compositeArray) });
            } else {
                return new FormGroup({
                    null: this.field.isMandatory
                        ? new FormControl("", [
                              Validators.required,
                              this.customValidator(this.field.dataTypeId),
                          ])
                        : new FormControl("", [
                              this.customValidator(this.field.dataTypeId),
                          ]),
                });
            }
        }
    }
    public uuidv4() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    saveConditionalUpdateData($event) {
        // console.log(this.form);
        // console.log($event);
        let temp: any = {};
        temp[this.field.items[0].value] = $event.updatedData.value;
        this.form.value[this.field.id][0][this.field.items[0].id] = temp;
        this.checkDataUpdation();
        // this.form.value[this.field.id] = temp;
        // console.log(this.form);
        // console.log($event.updatedData);
    }

    addFormControl(
        bookingLocationId,
        item,
        bookingLocationIdItem,
        items
    ): void {
        this.selectedItem = item;
        if (bookingLocationId != null) {
            items.forEach((element) => {
                this.blItems = Array.from(
                    new Set(this.blItems.concat(element.bookingLocationId.id))
                );
            });

            this.bookingLocationId = bookingLocationIdItem;
            this.bookingLocationLocale = bookingLocationId;
            this.checkDataUpdation();
            this.field.items.map((x) => {
                if (x.bookingLocationId.id != null) {
                    this.uniqueBookingLocation.push(x);
                }
            });
            let numberOfFieldsToBeadd: any = 0;
            this.blItems.forEach((element) => {
                if (!element.hasOwnProperty("null")) {
                    numberOfFieldsToBeadd++;
                }
            });

            for (let i = 0; i < numberOfFieldsToBeadd; i++) {
                this.blId = this.blItems[i];
                this.items.insert(this.items.length, this.newFormControl);
            }
        } else {
            this.checkDataUpdation();
            this.bookingLocationId = null;
            this.bookingLocationLocale = null;
            this.items.insert(this.items.length, this.newFormControl);
        }
    }

    filterSearch(searchedText, value) {
        return (
            searchedText &&
            !value.toLowerCase().includes(searchedText.toLowerCase())
        );
    }
    removeFormControl(i, bookingLocationId, id): void {
        let count = this.blItems.length;
        let CountIncrement = 0;
        const nonRemovedArray = [];
        const removedArray = [];
        if (bookingLocationId != undefined || bookingLocationId != null) {
            this.field.items.map((value, index) => {
                if (i == index && count > CountIncrement) {
                    CountIncrement++;

                    removedArray.push(value);
                    i = i + 1;
                    index = index + 1;
                } else {
                    nonRemovedArray.push(value);
                }
            });
            removedArray.map((value, index) => {
                this.field.items.map((item, x: number) => {
                    if (item == value) {
                        this.items.removeAt(x);
                        this.field.items.splice(x, 1);
                    } else {
                    }
                });
            });

            this.field.items = nonRemovedArray;
        } else {
            this.field.items.splice(i, 1);
            this.items.removeAt(i);
        }
    }
    checkerReview(event, i) {
        if (!event.checked) {
            this.field.items[i].fieldReviewInfo = null;
        }
        this.field.isCheckerReviewer = event.checked;
    }
    updateDedupeFieldValue(event, i) {
        this.dedupeField.emit({
            fieldInfo: this.field.items[i],
            checked: event.checked,
        });
    }

    customValidator(dataType): ValidatorFn {
        return (c: AbstractControl): { [key: string]: any } | null => {
            if (c.value !== "") {
                if (dataType == 202) {
                    const regExpAll = /^([a-zA-Z0-9 _-]+)$/;
                    if (!regExpAll.test(c.value)) {
                        return { allDataType: true };
                    } else {
                        return null;
                    }
                } else if (dataType == 203) {
                    const regExpAll = /^[0-9]+$/;
                    if (!regExpAll.test(c.value)) {
                        return { numberDataType: true };
                    } else {
                        return null;
                    }
                } else if (dataType == 204) {
                    const regExpAll = /^[a-zA-Z ]+$/;
                    if (!regExpAll.test(c.value)) {
                        return { alphabetDataType: true };
                    } else {
                        return null;
                    }
                } else if (dataType == 205) {
                    const regExpAll = /^[0-9. ]+$/;
                    if (!regExpAll.test(c.value)) {
                        return { decimalType: true };
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }
        };
    }

    saveUpdateValue($event) {
        this.checkDataUpdation();
    }

    onBlur(event: any, field: any, formValue: any, index: number) {
        this.fieldBlur.emit({ event, field, formValue, index }); // Emit event
    }

    checkDataUpdation(field?) {
        this.saveUpdateData.emit({ updatedData: true });
        if (field?.isNameScreening) {
            this.nameScreeningFieldChanged.emit({ changed: true });
        }
        // console.log("value ", value);
    }

    updateConditionalFields(value) {
        // console.log(value);
        this.childControl = value.toString();
        this.conditionalFields = this.field;
        let filteredConditionalFields = [];
        this.field.fieldTypeId == 104
            ? value.forEach((val) => {
                  this.conditionalFieldListItems.forEach((item) => {
                      if (val == item.parentFieldId.toString()) {
                          filteredConditionalFields.push(item);
                      }
                  });
              })
            : this.conditionalFieldListItems.forEach((item) => {
                  if (value == item.parentFieldId.toString()) {
                      filteredConditionalFields.push(item);
                  }
              });
        this.conditionalFields.items[0].conditionalFieldListItems =
            filteredConditionalFields;
        this.conditionalFields.items[0].value = value;
        let temp: any = [];
        this.conditionalFields.items[0].conditionalFieldListItems.sort(
            (a, b) => {
                if (
                    a.conditionalDetails.displayOrder >
                    b.conditionalDetails.displayOrder
                ) {
                    return 1;
                } else {
                    return 0;
                }
            }
        );
        temp.push(this.conditionalFields);
        this.conditionalForm = this._mfcs.toFormArray(
            temp,
            true,
            this.rolePermissions
        );
        const fieldId = this.field.id;
        this.previousChildValue &&
            (
                this.form.get(fieldId.toString()).get("0") as FormGroup
            ).removeControl(this.previousChildValue.toString());

        (this.form.get(fieldId.toString()).get("0") as FormGroup).addControl(
            value.toString(),
            this.conditionalForm
        );
        this.previousChildValue = value;
        // console.log(this.form);
    }
}
