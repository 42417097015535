import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "lib-trigger-comment",
    templateUrl: "./trigger-comment.component.html",
    styleUrls: ["./trigger-comment.component.css"],
})
export class TriggerCommentComponent implements OnInit {
    constructor(private dialogRef: MatDialogRef<TriggerCommentComponent>) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {}

    comments = "";

    closeDialog() {
        console.log(this.comments);
        this.dialogRef.close({ data: this.comments });
    }
    save() {
        this.dialogRef.close({ data: this.comments });
    }
    cancel() {
        this.dialogRef.close();
    }
}
