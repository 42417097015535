import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { StorageService } from "dil-shared";
import { Observable } from "rxjs";
import { catchError } from "rxjs/internal/operators";
import { SSOService } from "../../oauth2/sso/sso.service";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    constructor(
        private ssoService: SSOService,
        private storageService: StorageService,
        private oauthService: OAuthService,
        private http: HttpClient
    ) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        let isLoggedIn = this.ssoService.isLoggedIn();
        if (isLoggedIn) {
            this.ssoService.checkSession();
            return this.storageService
                .getItem("accessRights")
                ?.includes(next.data.name.toUpperCase());
        }
        return false;
    }
}
