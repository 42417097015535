import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TriggerEventService {

  private base_url = environment.base_url;
  private orgType = `${environment.base_url}/config/idv/latest-orgtype`;
  _countryURL = `${environment.base_url}/master/category/content?codes=CONT`;
  _NationalityURL = `${environment.base_url}/config/country/nationality`;

  constructor(
    private http: HttpClient
  ) {
  }

  getTriggerEventTypes() {
    let base_url_event_type: string = `${this.base_url}/master/category?codes=TRIGR_EVNT_TYPE`;
    let response1 = this.http.get<any[]>(base_url_event_type);
    return forkJoin([response1]);
  }

  getMasterDataFields() {
    let base_url_event_type: string = `${this.base_url}/master/category?codes=FILD_IND`;
    let response1 = this.http.get<any[]>(base_url_event_type);
    return forkJoin([response1]);
  }

  searchCustomerDetails(segType, key, value) {
    let search_customer_url: string = `${this.base_url}/profile/review/trigger_event/search/${segType}?${key}=${value}`;
    let response1 = this.http.get<any[]>(search_customer_url);
    return forkJoin([response1]);
  }

  triggerCustomerDetails(body) {
    let trigger_customer_url: string = `${this.base_url}/profile/review/trigger_event/create`;
    let response1 = this.http.post(trigger_customer_url, body);
    return forkJoin([response1]);
  }

  getContentData(contentId: string) {
    let base_url_content: string = `${this.base_url}/master/category/content/list?contentIds=${contentId}`;
    let response1 = this.http.get<any[]>(base_url_content);
    return forkJoin([response1]);
  }

  getBookingLocationData(id) {
    return forkJoin(
      this.http.get(`${this.orgType}/${id}`),
      this.http.get<any>(this._countryURL)
    );
  }

  getNationalityData() {
    return forkJoin(this.http.get(this._NationalityURL), this.http.get<any>(this._countryURL));
  }
}
